import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "react-redux-firebase";
import { Redirect } from "react-router-dom";

// Components
import UsersTabs from "./components/UsersTabs";
import UsersTable from "./components/UsersTable";
import LoadingBlock from "../../components/LoadingBlock";

class usersManage extends Component {
  state = {
    perPage: 50
  };
  showMore = current => {
    let more = Number(current) + 50;
    this.setState({ perPage: more });
  };
  render() {
    const { auth, profile } = this.props;

    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }

    // If everything went to plan
    if (profile.isLoaded) {
      return (
        <div>
          <h1>Manage Users</h1>
          <UsersTable perPage={this.state.perPage} showMore={this.showMore} />
          <UsersTabs />
        </div>
      );
    }

    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, null)(usersManage);
