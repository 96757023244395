import React from "react";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Components
import LoadingBlock from "../components/LoadingBlock";

// Images
import UsersIcon from "../img/icon-users.svg";
import QuoteIcon from "../img/icon-write.svg";
import DocumentsIcon from "../img/icon-list.svg";
import PricingIcon from "../img/icon-cash.svg";

// Functions
import { getLink } from "../util/functions";

const home = ({ auth, profile }) => {
  if (!auth.uid) return <Redirect to="/login" />;

  return (
    <div>
      {profile.permission ? (
        <div className="nav-blocks">
          {profile.permission === "Admin" && (
            <NavLink to={getLink("Users")}>
              <div className="nav-blocks--block">
                <span className="icon">
                  <img src={UsersIcon} alt="Users" />
                </span>
                <span className="label">Users</span>
              </div>
            </NavLink>
          )}
          <NavLink to={getLink("Quote", profile.permission)}>
            <div className="nav-blocks--block">
              <span className="icon">
                <img src={QuoteIcon} alt="Quote" />
              </span>
              <span className="label">
                Contract
                <br />
                Quote
              </span>
            </div>
          </NavLink>
          <NavLink to={getLink("Documents", profile.permission)}>
            <div className="nav-blocks--block">
              <span className="icon">
                <img src={DocumentsIcon} alt="Documents" />
              </span>
              <span className="label">Documents</span>
            </div>
          </NavLink>
          {profile.permission === "Admin" && profile.pricingaccess && (
            <NavLink to="/pricing/manage/">
              <div className="nav-blocks--block">
                <span className="icon">
                  <img src={PricingIcon} alt="Users" />
                </span>
                <span className="label">
                  Manage
                  <br />
                  Pricing
                </span>
              </div>
            </NavLink>
          )}
        </div>
      ) : (
        <LoadingBlock />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(home);
