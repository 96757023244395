import authReducer from "./authReducer";
import userReducer from "./userReducer";
import globalReducer from "./globalReducer";
import quoteReducer from "./quoteReducer";
import documentReducer from "./documentReducer";
import pricingReducer from "./pricingReducer";

import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  user: userReducer,
  global: globalReducer,
  quote: quoteReducer,
  document: documentReducer,
  pricing: pricingReducer
});

export default rootReducer;
