import React from "react";
import { NavLink } from "react-router-dom";

// Images
import BuildIcon from "../../../img/icon-write.svg";
import OpenIcon from "../../../img/icon-list.svg";
import ClosedIcon from "../../../img/icon-box-open.svg";
import TrashIcon from "../../../img/icon-trash.svg";

const QuoteTabs = () => {
  return (
    <div className="tabs">
      <NavLink to="/quote/request" activeClassName="active">
        <img src={BuildIcon} alt="Build" />
        Build
      </NavLink>
      <NavLink to="/quote/open" activeClassName="active">
        <img src={OpenIcon} alt="View" />
        Open
      </NavLink>
      <NavLink to="/quote/closed" activeClassName="active">
        <img src={ClosedIcon} alt="View" />
        Closed
      </NavLink>

      <NavLink to="/quote/deleted" activeClassName="active">
        <img src={TrashIcon} alt="View" />
        Deleted
      </NavLink>
    </div>
  );
};

export default QuoteTabs;
