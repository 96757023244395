import React from "react";
import { NavLink } from "react-router-dom";

// Images
import RequestIcon from "../../../img/icon-upload.svg";
import ActiveIcon from "../../../img/icon-list.svg";

const DocumentTabs = () => {
  return (
    <div className="tabs">
      <NavLink to="/documents/add" activeClassName="active">
        <img src={RequestIcon} alt="Add" />
        Add Document
      </NavLink>
      <NavLink to="/documents/manage" activeClassName="active">
        <img src={ActiveIcon} alt="Manage" />
        Manage Documents
      </NavLink>
    </div>
  );
};

export default DocumentTabs;
