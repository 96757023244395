import React, { Component } from "react";

// Components
import AddPricingDialog from "./AddPricingDialog";

// MUI Stuff
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Images
import NewIcon from "../../../img/icon-write.svg";

class PricingTableAddRow extends Component {
  state = {
    viewOpen: false
  };

  handleUpdate = () => {
    this.setState({ viewOpen: true });
  };
  handleUpdateClose = () => {
    this.setState({ viewOpen: false });
  };

  render() {
    const { collection } = this.props;

    return (
      <TableRow>
        <TableCell className="actions" colSpan="4" align="center">
          <Button
            className="new"
            variant="contained"
            color="primary"
            onClick={this.handleUpdate}
          >
            <img src={NewIcon} alt="icon" />
            Add New Row
          </Button>
        </TableCell>
        <AddPricingDialog
          rowId={null}
          singleRow={null}
          open={this.state.viewOpen}
          handleClose={this.handleUpdateClose}
          collection={collection}
        />
      </TableRow>
    );
  }
}

export default PricingTableAddRow;
