import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { signIn } from "../store/actions/authActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class login extends Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };
  render() {
    const { authErrorMessage, authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/" />;
    return (
      <div>
        <h1>Sign in</h1>
        {authError && (
          <div className="error-message">
            <p>{authErrorMessage}</p>
          </div>
        )}
        <form onSubmit={this.handleSubmit}>
          <div className="field">
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <TextField
              id="password"
              label="Password"
              type="password"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <Button type="submit" variant="contained" color="primary">
              Sign in
            </Button>
          </div>
        </form>
        <p>
          Can't remember your password? <a href="/reset">Reset it here</a>.
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authErrorMessage: state.auth.authErrorMessage,
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => dispatch(signIn(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(login);
