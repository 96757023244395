import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";

// MUI Stuff
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

// Images
import AppLogo from "../../img/pmm-logo-white.svg";

class Navbar extends Component {
  state = {
    leftNav: false,
    rightNav: false
  };
  handleLeftToggle = () => this.setState({ leftNav: !this.state.leftNav });
  handleRightToggle = () => this.setState({ rightNav: !this.state.rightNav });
  closeToggles = () => this.setState({ leftNav: false, rightNav: false });
  render() {
    const { auth, profile } = this.props;

    return (
      <div>
        <AppBar position="static">
          <Toolbar className="nav-container">
            {auth.uid && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Menu"
                onClick={this.handleLeftToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
            <NavLink to="/" className="logo">
              <img src={AppLogo} alt="logo" />
            </NavLink>
            {auth.uid && (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Account"
                onClick={this.handleRightToggle}
              >
                <AccountCircle />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={this.state.leftNav}
          onClose={this.closeToggles}
        >
          <div className="nav-left" onClick={this.closeToggles}>
            <ul>
              {profile.permission === "Admin" && (
                <>
                  <MenuItem className="parent">
                    <NavLink to="/users/manage">Manage Users</NavLink>
                  </MenuItem>
                  <MenuItem className="parent">
                    <NavLink to="/quote/open">Contract Quote</NavLink>
                  </MenuItem>
                  <MenuItem className="parent">
                    <NavLink to="/documents/manage">Documents</NavLink>
                  </MenuItem>
                  {profile.pricingaccess && (
                    <MenuItem className="parent">
                      <NavLink to="/pricing/manage">Manage Pricing</NavLink>
                    </MenuItem>
                  )}
                </>
              )}
              {profile.permission === "Manager" && (
                <>
                  <MenuItem className="parent">
                    <NavLink to="/quote/open">Contract Quote</NavLink>
                  </MenuItem>
                  <MenuItem className="parent">
                    <NavLink to="/documents/view">Documents</NavLink>
                  </MenuItem>
                </>
              )}
              {profile.permission === "Rep" && (
                <>
                  <MenuItem className="parent">
                    <NavLink to="/quote/request">Contract Quote</NavLink>
                  </MenuItem>
                  <MenuItem className="parent">
                    <NavLink to="/documents/view">Documents</NavLink>
                  </MenuItem>
                </>
              )}
            </ul>
          </div>
        </Drawer>
        <Drawer
          anchor="right"
          open={this.state.rightNav}
          onClose={this.closeToggles}
        >
          <div className="nav-right" onClick={this.closeToggles}>
            <p>Logged in as</p>
            <h3>{profile.name}</h3>
            <p>Account type</p>
            <h3>{profile.permission}</h3>
            {profile.region && (
              <div>
                <p>Region</p>
                <h3>{profile.region}</h3>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.signOut}
            >
              Sign Out
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
