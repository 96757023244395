import "date-fns";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { savePricing } from "../../store/actions/pricingActions";

// Components
import LoadingBlock from "../global/LoadingBlock";
import CSVReader from "react-csv-reader";
import PricingTable from "./components/PricingTable";

// Material UI
import Button from "@material-ui/core/Button";

class pricingManage extends Component {
  state = {
    saving: false,
    csvError: ""
  };

  componentDidUpdate(prevProps) {
    if (this.props.pricingUpdate !== prevProps.pricingUpdate) {
      this.setState({
        saving: false,
        csvError: ""
      });
    }
    if (this.props.pricingError !== prevProps.pricingError) {
      this.setState({
        csvError: this.props.pricingError
      });
    }
  }

  handleCsvLoaded = data => {
    this.setState({ saving: true });

    // Verify CSV has data
    if (data.length === 0) {
      this.setState({
        csvHelper: "The uploaded CSV file is empty",
        csvError: true
      });
      return;
    }

    // Verify CSV data matches the format
    const columns = [
      "Manufacturer",
      "Equipment Type",
      "Model",
      "Labor Bi-monthly",
      "Labor Quarterly",
      "Labor Semi-annual",
      "Labor Annual",
      "P & L Bi-monthly",
      "P & L Quarterly",
      "P & L Semi-annual",
      "P & L Annual",
      "Full Bi-monthly",
      "Full Quarterly",
      "Full Semi-annual",
      "Full Annual"
    ];

    let rowTest = data[0];
    let headerError = false;

    columns.forEach(key => {
      if (!rowTest.hasOwnProperty(key)) {
        this.setState({
          csvHelper:
            "This CSV file is missing a " +
            key +
            " column. Please note: column names need to be exact and are case-sensitive.",
          csvError: true,
          saving: false
        });
        headerError = true;
        return;
      }
    });

    if (headerError) {
      return;
    }

    // Validate and sanitize data in CSV
    let cleanData = [];
    const stringTitles = {
      Manufacturer: "manufacturer",
      "Equipment Type": "equipmentType",
      Model: "model"
    };
    const numberTitles = {
      "Labor Bi-monthly": "laborBiMonthly",
      "Labor Quarterly": "laborQuarterly",
      "Labor Semi-annual": "laborSemiAnnual",
      "Labor Annual": "laborAnnual",
      "P & L Bi-monthly": "partsAndLaborBiMonthly",
      "P & L Quarterly": "partsAndLaborQuarterly",
      "P & L Semi-annual": "partsAndLaborSemiAnnual",
      "P & L Annual": "partsAndLaborAnnual",
      "Full Bi-monthly": "fullBiMonthly",
      "Full Quarterly": "fullQuarterly",
      "Full Semi-annual": "fullSemiAnnual",
      "Full Annual": "fullAnnual"
    };

    // Remove rows missing manufacturer, equipment type and make
    data = data.filter(row => {
      return (
        row["Manufacturer"] !== null &&
        row["Equipment Type"] !== null &&
        row["Make"] !== null
      );
    });

    // Map data, clean numbers and push to new array
    data.forEach(row => {
      let cleanRow = {};

      Object.keys(stringTitles).forEach(key => {
        cleanRow[stringTitles[key]] = row[key];
      });

      Object.keys(numberTitles).forEach(key => {
        let value = row[key];

        if (value) {
          value = row[key].toString();
          value = value.replaceAll(",", "");
          value = value.replaceAll("$", "");
          value = value.replaceAll(" ", "");
          cleanRow[numberTitles[key]] = Number(value);
        } else {
          cleanRow[numberTitles[key]] = 0;
        }
      });

      cleanData.push(cleanRow);
    });

    this.props.savePricing(cleanData);
  };

  handleCsvError = () => {
    this.setState({
      csvHelper: "There was an error loading the CSV file",
      csvError: true,
      saving: false
    });
  };

  handleDownload = () => {
    const currentPricing = this.props.quoteprices;

    let formatted = [];

    const headerRow = [
      "Manufacturer",
      "Equipment Type",
      "Model",
      "Labor Bi-monthly",
      "Labor Quarterly",
      "Labor Semi-annual",
      "Labor Annual",
      "P & L Bi-monthly",
      "P & L Quarterly",
      "P & L Semi-annual",
      "P & L Annual",
      "Full Bi-monthly",
      "Full Quarterly",
      "Full Semi-annual",
      "Full Annual"
    ];

    formatted.push(headerRow);

    currentPricing.forEach(row => {
      let priceRow = [
        row.manufacturer,
        row.equipmentType,
        row.model,
        row.laborBiMonthly,
        row.laborQuarterly,
        row.laborSemiAnnual,
        row.laborAnnual,
        row.partsAndLaborBiMonthly,
        row.partsAndLaborQuarterly,
        row.partsAndLaborSemiAnnual,
        row.partsAndLaborAnnual,
        row.fullBiMonthly,
        row.fullQuarterly,
        row.fullSemiAnnual,
        row.fullAnnual
      ];
      formatted.push(priceRow);
    });

    let csvContent =
      "data:text/csv;charset=utf-8," +
      formatted.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "current_pmm_pricing.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { auth, quoteprices } = this.props;

    // Redirect if logged out
    if (!auth.uid) return <Redirect to="/login" />;

    const parseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true
    };

    const getCsvHelperClass = () => {
      if (this.state.csvError) {
        return "pricing--upload--helper error";
      }
      return "pricing--upload--helper";
    };

    return (
      <div>
        <h1>Manage Pricing</h1>
        <div className="pricing">
          <div className="pricing--upload">
            <p>
              Upload a CSV to replace all pricing or download a CSV file with
              current pricing,
              <span>
                (CSV files should have 5 columns: make, model, laborOnly,
                partsAndLabor, fullService)
              </span>
            </p>
            <div className="pricing--buttons">
              <div className="pricing--buttons--col">
                <h3>Upload CSV</h3>
                <CSVReader
                  cssClass="csv-reader-input"
                  label="Upload CSV"
                  onFileLoaded={this.handleCsvLoaded}
                  onError={this.handleCsvError}
                  parserOptions={parseOptions}
                  inputId="pricingCsv"
                  inputName="pricingCsv"
                />
              </div>
              <div className="pricing--buttons--col">
                <h3>Download CSV</h3>
                <Button
                  className="pricing--download"
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownload}
                >
                  Download Current Pricing
                </Button>
              </div>
            </div>
            {this.state.csvHelper !== "" && (
              <div className={getCsvHelperClass()}>{this.state.csvHelper}</div>
            )}
          </div>
          {!isLoaded(quoteprices) || this.state.saving ? (
            <LoadingBlock />
          ) : isEmpty(quoteprices) ? (
            <h2>No Pricing Found</h2>
          ) : (
            <PricingTable quoteprices={quoteprices} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    quoteprices: state.firestore.ordered.quoteprices,
    auth: state.firebase.auth,
    pricingUpdate: state.pricing.pricingUpdate,
    pricingError: state.pricing.pricingError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    savePricing: data => dispatch(savePricing(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "quoteprices",
      orderBy: [
        ["manufacturer", "asc"],
        ["equipmentType", "asc"],
        ["model", "asc"]
      ]
    }
  ])
)(pricingManage);
