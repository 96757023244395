import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { reformatTimestamp, formatUSD } from "../../../util/functions";

// Actions
import { updateQuote } from "../../../store/actions/quoteActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

// Images
import AddIcon from "../../../img/icon-circle-plus.svg";
import RemoveIcon from "../../../img/icon-circle-x.svg";
import CopyIcon from "../../../img/icon-circle-copy.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class QuoteDialog extends Component {
  state = {
    id: this.props.rowId,
    serviceFacilityName: this.props.singleRow.serviceFacilityName,
    facilityContactName: this.props.singleRow.facilityContactName,
    facilityContactPhone: this.props.singleRow.facilityContactPhone,
    facilityContactEmail: this.props.singleRow.facilityContactEmail,
    facilityContactTitle: this.props.singleRow.facilityContactTitle,
    serviceFacilityAddress: this.props.singleRow.serviceFacilityAddress,
    serviceFacilityAddressCity: this.props.singleRow.serviceFacilityAddressCity,
    serviceFacilityAddressState: this.props.singleRow
      .serviceFacilityAddressState,
    serviceFacilityAddressZip: this.props.singleRow.serviceFacilityAddressZip,
    billingSame: this.props.singleRow.billingSame,
    billFacilityName: this.props.singleRow.billFacilityName,
    billFacilityAddress: this.props.singleRow.billFacilityAddress,
    billFacilityAddressCity: this.props.singleRow.billFacilityAddressCity,
    billFacilityAddressState: this.props.singleRow.billFacilityAddressState,
    billFacilityAddressZip: this.props.singleRow.billFacilityAddressZip,
    serviceTermStartDate: this.props.singleRow.serviceTermStartDate,
    serviceTermEndDate: this.props.singleRow.serviceTermEndDate,
    serviceTerm: this.props.singleRow.serviceTerm,
    purchaseOrder: this.props.singleRow.purchaseOrder,
    closeDate: this.props.singleRow.closeDate,
    contractType: this.props.singleRow.contractType
      ? this.props.singleRow.contractType
      : "",
    notes: this.props.singleRow.notes,
    customerType: this.props.singleRow.customerType,
    customerStatus: this.props.singleRow.customerStatus,
    managedBy: this.props.singleRow.managedBy,
    currentContract: this.props.singleRow.currentContract,
    currentContractExpiration: this.props.singleRow.currentContractExpiration,
    labor: this.props.singleRow.labor,
    partsAndLabor: this.props.singleRow.partsAndLabor,
    full: this.props.singleRow.full,
    includeSignature: this.props.singleRow.includeSignature
      ? this.props.singleRow.includeSignature
      : null,
    signatureDate: this.props.singleRow.signatureDate
      ? this.props.singleRow.signatureDate
      : null,
    discount: this.props.singleRow.discount,
    multiplier: this.props.singleRow.multiplier,
    status: this.props.singleRow.status,
    closedStatus: this.props.singleRow.closedStatus
      ? this.props.singleRow.closedStatus
      : "Labor Only",
    internalNotes: this.props.singleRow.internalNotes
      ? this.props.singleRow.internalNotes
      : "",
    equipment: this.props.singleRow.equipment,
    valueOverride: this.props.singleRow.valueOverride
      ? this.props.singleRow.valueOverride
      : "",
    formError: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };

  handleDateChange = (date, key) => {
    this.setState({
      [key]: date
    });
  };

  getSelectedTotals = singleRow => {
    let totals = [];

    const coverages = ["labor", "partsAndLabor", "full"];
    const coveragesNameMap = {
      labor: "Labor Only",
      partsAndLabor: "Parts and Labor",
      full: "Full Service"
    };

    coverages.forEach(level => {
      if (singleRow[level] === true) {
        const combinedKey = "total_" + level;
        if (singleRow.hasOwnProperty(combinedKey)) {
          let row = {
            value: formatUSD(Number(singleRow[combinedKey])),
            label: coveragesNameMap[level]
          };
          totals.push(row);
        }
      }
    });

    return totals;
  };

  handleEquipmentTypeChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return { ...row, equipmentType: e.target.value };
    });
    this.setState({ equipment: newEquipment });
  };

  handleEquipmentManufacturerChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return { ...row, manufacturer: e.target.value };
    });
    this.setState({ equipment: newEquipment });
  };

  handleEquipmentModelChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      const { id } = e.currentTarget.dataset;
      return {
        ...row,
        model: e.target.value,
        docId: id
      };
    });
    this.setState({ equipment: newEquipment });
  };

  handleEquipmentTextChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return { ...row, [e.target.id]: e.target.value };
    });

    this.setState({
      equipment: newEquipment
    });
  };

  handleEquipmentFrequencyChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return {
        ...row,
        frequency: e.target.value
      };
    });
    this.setState({ equipment: newEquipment });
  };

  removeEquipmentRow = i => () => {
    let newEquipment = this.state.equipment;

    if (i > -1) {
      newEquipment.splice(i, 1);
    }

    this.setState({
      equipment: newEquipment
    });
  };

  addEquipmentRow = i => () => {
    this.setState({
      equipment: this.state.equipment.concat([
        {
          equipmentType: "",
          manufacturer: "",
          model: "",
          serial: "",
          location: "",
          customName: "",
          customCost: ""
        }
      ])
    });
  };

  duplicateEquipmentRow = i => () => {
    let newEquipment = this.state.equipment;
    let rowToDup = newEquipment[i];

    newEquipment.splice(i + 1, 0, rowToDup);

    this.setState({
      equipment: newEquipment
    });
  };

  handleClose = () => {
    this.setState({
      status: this.props.singleRow.status
    });
    this.props.handleClose();
  };

  handleSave = () => {
    if (
      this.state.labor === false &&
      this.state.partsAndLabor === false &&
      this.state.full === false
    ) {
      this.setState({
        formError:
          "Error: You must select at least one type of coverage to quote."
      });
      return;
    }

    this.setState({
      formError: ""
    });

    // Vars/maps used for keying
    const coverages = ["labor", "partsAndLabor", "full"];
    const frequencies = ["BiMonthly", "Quarterly", "SemiAnnual", "Annual"];
    const coveragesStringMap = {
      labor: "Labor Only",
      partsAndLabor: "Parts & Labor",
      full: "Full Service"
    };
    const frequenciesStringMap = {
      BiMonthly: "bi-monthly",
      Quarterly: "quarterly",
      SemiAnnual: "semi-annual",
      Annual: "annual"
    };
    const frequencyMultiplier = {
      BiMonthly: 6,
      Quarterly: 4,
      SemiAnnual: 2,
      Annual: 1
    };

    let dataToSave = { ...this.state };
    let equipment = dataToSave.equipment;
    let prices = this.props.quoteprices;

    let selectedCoverages = [];
    coverages.forEach(level => {
      if (dataToSave[level]) {
        selectedCoverages.push(level);
      }
    });

    // Calculate pricing
    let totals = {};
    let priceError = false;

    // Loop through equipment selected
    equipment.forEach(row => {
      // Bail is an error has already been thrown
      if (priceError) {
        return;
      }

      // Set default cost, replace with custom if specified
      let equipmentCost = 0;
      if (row.customCost !== "") {
        equipmentCost = row.customCost;
      }

      // Find pricing for matching equipment
      let match = null;
      let matches = prices.filter(m => {
        return (
          m.equipmentType === row.equipmentType &&
          m.manufacturer === row.manufacturer &&
          m.model === row.model
        );
      });
      if (matches.length > 0) {
        match = matches[0];
      }

      // Bail if all pricing is missing and no custom cost is available
      if (equipmentCost === 0 && !match) {
        priceError = true;
        this.setState({
          formError:
            "Error: " +
            row.manufacturer +
            " " +
            row.model +
            " is missing pricing."
        });
      }

      // Get pricing for each coverage level selected
      selectedCoverages.forEach(level => {
        const combinedKey = level + row.frequency;

        // Get the per-service cost for the unit
        let cost;
        if (equipmentCost !== 0) {
          cost = equipmentCost;
        } else {
          cost = match[combinedKey];
        }

        // Bail if a cost isn't available
        if (cost === 0) {
          priceError = true;
          this.setState({
            formError:
              row.manufacturer +
              " " +
              row.model +
              " is missing pricing for " +
              frequenciesStringMap[row.frequency] +
              " " +
              coveragesStringMap[level] +
              "."
          });
        }

        row[combinedKey] = Number(cost);
      });
    });

    // Bail from the submit process if a price error was detected
    if (priceError) {
      return;
    }

    dataToSave.equipment = equipment;

    // Update raw date formats to work with pickers
    dataToSave.serviceTermStartDateRaw = new Date(
      dataToSave.serviceTermStartDate
    );
    dataToSave.serviceTermEndDateRaw = new Date(dataToSave.serviceTermEndDate);
    dataToSave.closeDateRaw = new Date(dataToSave.closeDate);
    dataToSave.currentContractExpirationRaw = new Date(
      dataToSave.currentContractExpiration
    );
    dataToSave.signatureDateRaw = new Date(dataToSave.signatureDate);

    this.props.updateQuote(dataToSave);
    this.props.handleClose();
  };

  render() {
    const { singleRow, profile, quoteprices } = this.props;

    const quoteValues = this.getSelectedTotals(singleRow);

    // Equipment type dropdown menu
    const renderTypeList = () => {
      const typeList = [...new Set(quoteprices.map(row => row.equipmentType))];

      typeList.push("Custom");

      return typeList.map((val, i) => {
        return (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        );
      });
    };

    // Manufacturers dropdown menu
    const renderManufacturerList = equipmentType => {
      const manufacturerList = quoteprices.filter(
        row => row.equipmentType === equipmentType
      );
      const manufacturerListSorted = manufacturerList.sort((a, b) =>
        a.manufacturer > b.manufacturer ? 1 : -1
      );
      const manufacturerNames = [
        ...new Set(manufacturerListSorted.map(row => row.manufacturer))
      ];

      return manufacturerNames.map((val, i) => {
        return (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        );
      });
    };

    // Models dropdown menu
    const renderModelList = (equipmentType, manufacturer) => {
      const modelList = quoteprices.filter(
        row =>
          row.equipmentType === equipmentType &&
          row.manufacturer === manufacturer
      );
      const modelListSorted = modelList.sort((a, b) =>
        a.model > b.model ? 1 : -1
      );

      return modelListSorted.map((model, i) => {
        return (
          <MenuItem key={i} value={model.model} data-id={model.id}>
            {model.model}
          </MenuItem>
        );
      });
    };

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        className="dialog--edit"
      >
        <AppBar className="modal-appbar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {singleRow.serviceFacilityName}
            </Typography>
            {this.state.formError !== "" && (
              <div className="modal-appbar--error">{this.state.formError}</div>
            )}
            <Button autoFocus color="inherit" onClick={this.handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div className="update" style={{ paddingBottom: 0 }}>
          <div className="update--column">
            <h2>Facility Information</h2>
            <div className="dialog--input">
              <TextField
                id="serviceFacilityName"
                label="Service Facility"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.serviceFacilityName}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="facilityContactName"
                label="Facility Contact"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactName}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="facilityContactPhone"
                label="Phone"
                variant="filled"
                type="phone"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactPhone}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="facilityContactEmail"
                label="Email"
                variant="filled"
                type="email"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactEmail}
              ></TextField>
            </div>

            <div className="dialog--input">
              <TextField
                id="facilityContactTitle"
                label="Facility Contact Title"
                variant="filled"
                type="text"
                onChange={this.handleChange}
                defaultValue={singleRow.facilityContactTitle}
              ></TextField>
            </div>

            <h3>Service Address</h3>
            <div className="dialog--input">
              <TextField
                id="serviceFacilityAddress"
                label="Service Address"
                type="text"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.serviceFacilityAddress}
              />
            </div>
            <div className="dialog--input">
              <TextField
                id="serviceFacilityAddressCity"
                label="City"
                type="text"
                variant="filled"
                onChange={this.handleChange}
                defaultValue={singleRow.serviceFacilityAddressCity}
              />
            </div>
            {singleRow.userRegion === "United Kingdom" ? (
              <div>
                <div className="dialog--input">
                  <TextField
                    id="serviceFacilityAddressZip"
                    label="Postcode"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="dialog--input">
                  <FormControl variant="filled">
                    <InputLabel id="serviceFacilityAddressState-label">
                      State
                    </InputLabel>
                    <Select
                      id="serviceFacilityAddressState"
                      labelId="serviceFacilityAddressState-label"
                      variant="filled"
                      label="State"
                      value={this.state.serviceFacilityAddressState}
                      onChange={this.handleSelectChange(
                        "serviceFacilityAddressState"
                      )}
                    >
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="dialog--input">
                  <TextField
                    id="serviceFacilityAddressZip"
                    label="Zip"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.serviceFacilityAddressZip}
                  />
                </div>
              </div>
            )}
            <h3>Billing Address</h3>
            <label>
              <Checkbox
                id="billingSame"
                label="Same as Service Info"
                checked={this.state.billingSame}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">Same as Service Info</span>
            </label>
            {this.state.billingSame === false && (
              <div>
                <div className="dialog--input">
                  <TextField
                    id="billFacilityName"
                    label="Billing Facility Name"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.billFacilityName}
                  />
                </div>
                <div className="dialog--input">
                  <TextField
                    id="billFacilityAddress"
                    label="Billing Address"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.billFacilityAddress}
                  />
                </div>
                <div className="dialog--input">
                  <TextField
                    id="billFacilityAddressCity"
                    label="City"
                    type="text"
                    variant="filled"
                    onChange={this.handleChange}
                    defaultValue={singleRow.billFacilityAddressCity}
                  />
                </div>
                {singleRow.userRegion === "United Kingdom" ? (
                  <div className="dialog--input">
                    <TextField
                      id="billFacilityAddressZip"
                      label="Postcode"
                      type="text"
                      variant="filled"
                      onChange={this.handleChange}
                      defaultValue={singleRow.billFacilityAddressZip}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="dialog--input">
                      <FormControl variant="filled">
                        <InputLabel id="billFacilityAddressState-label">
                          State
                        </InputLabel>
                        <Select
                          id="billFacilityAddressState"
                          labelId="billFacilityAddressState-label"
                          variant="filled"
                          label="State"
                          value={this.state.billFacilityAddressState}
                          onChange={this.handleSelectChange(
                            "billFacilityAddressState"
                          )}
                        >
                          <MenuItem value="AL">Alabama</MenuItem>
                          <MenuItem value="AK">Alaska</MenuItem>
                          <MenuItem value="AZ">Arizona</MenuItem>
                          <MenuItem value="AR">Arkansas</MenuItem>
                          <MenuItem value="CA">California</MenuItem>
                          <MenuItem value="CO">Colorado</MenuItem>
                          <MenuItem value="CT">Connecticut</MenuItem>
                          <MenuItem value="DE">Delaware</MenuItem>
                          <MenuItem value="DC">District Of Columbia</MenuItem>
                          <MenuItem value="FL">Florida</MenuItem>
                          <MenuItem value="GA">Georgia</MenuItem>
                          <MenuItem value="HI">Hawaii</MenuItem>
                          <MenuItem value="ID">Idaho</MenuItem>
                          <MenuItem value="IL">Illinois</MenuItem>
                          <MenuItem value="IN">Indiana</MenuItem>
                          <MenuItem value="IA">Iowa</MenuItem>
                          <MenuItem value="KS">Kansas</MenuItem>
                          <MenuItem value="KY">Kentucky</MenuItem>
                          <MenuItem value="LA">Louisiana</MenuItem>
                          <MenuItem value="ME">Maine</MenuItem>
                          <MenuItem value="MD">Maryland</MenuItem>
                          <MenuItem value="MA">Massachusetts</MenuItem>
                          <MenuItem value="MI">Michigan</MenuItem>
                          <MenuItem value="MN">Minnesota</MenuItem>
                          <MenuItem value="MS">Mississippi</MenuItem>
                          <MenuItem value="MO">Missouri</MenuItem>
                          <MenuItem value="MT">Montana</MenuItem>
                          <MenuItem value="NE">Nebraska</MenuItem>
                          <MenuItem value="NV">Nevada</MenuItem>
                          <MenuItem value="NH">New Hampshire</MenuItem>
                          <MenuItem value="NJ">New Jersey</MenuItem>
                          <MenuItem value="NM">New Mexico</MenuItem>
                          <MenuItem value="NY">New York</MenuItem>
                          <MenuItem value="NC">North Carolina</MenuItem>
                          <MenuItem value="ND">North Dakota</MenuItem>
                          <MenuItem value="OH">Ohio</MenuItem>
                          <MenuItem value="OK">Oklahoma</MenuItem>
                          <MenuItem value="OR">Oregon</MenuItem>
                          <MenuItem value="PA">Pennsylvania</MenuItem>
                          <MenuItem value="RI">Rhode Island</MenuItem>
                          <MenuItem value="SC">South Carolina</MenuItem>
                          <MenuItem value="SD">South Dakota</MenuItem>
                          <MenuItem value="TN">Tennessee</MenuItem>
                          <MenuItem value="TX">Texas</MenuItem>
                          <MenuItem value="UT">Utah</MenuItem>
                          <MenuItem value="VT">Vermont</MenuItem>
                          <MenuItem value="VA">Virginia</MenuItem>
                          <MenuItem value="WA">Washington</MenuItem>
                          <MenuItem value="WV">West Virginia</MenuItem>
                          <MenuItem value="WI">Wisconsin</MenuItem>
                          <MenuItem value="WY">Wyoming</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="dialog--input">
                      <TextField
                        id="billFacilityAddressZip"
                        label="Zip"
                        type="text"
                        variant="filled"
                        onChange={this.handleChange}
                        defaultValue={singleRow.billFacilityAddressZip}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="update--column">
            <h2>Quote Information</h2>
            <h5>Date</h5>
            <p>{reformatTimestamp(singleRow.date.seconds)}</p>
            <h5>Types of Coverage Quoted</h5>
            <div className="dialog--input" style={{ marginBottom: 0 }}>
              <label>
                <Checkbox
                  id="labor"
                  label="Labor Only"
                  checked={this.state.labor}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">
                  Labor Only{" "}
                  {singleRow.laborFormURL && singleRow.laborFormURL !== "" && (
                    <>
                      - <a href={singleRow.laborFormURL}>Download Quote</a>
                    </>
                  )}
                </span>
              </label>
            </div>
            <div className="dialog--input" style={{ marginBottom: 0 }}>
              <label>
                <Checkbox
                  id="partsAndLabor"
                  label="Parts & Labor"
                  checked={this.state.partsAndLabor}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">
                  Parts & Labor{" "}
                  {singleRow.partsAndLaborFormURL &&
                    singleRow.partsAndLaborFormURL !== "" && (
                      <>
                        -{" "}
                        <a href={singleRow.partsAndLaborFormURL}>
                          Download Quote
                        </a>
                      </>
                    )}
                </span>
              </label>
            </div>
            <div className="dialog--input" style={{ marginBottom: 30 }}>
              <label>
                <Checkbox
                  id="full"
                  label="Full Service"
                  checked={this.state.full}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">
                  Full Service{" "}
                  {singleRow.fullFormURL && singleRow.fullFormURL !== "" && (
                    <>
                      - <a href={singleRow.fullFormURL}>Download Quote</a>
                    </>
                  )}
                </span>
              </label>
            </div>
            {this.state.includeSignature !== null && (
              <div style={{ marginBottom: 36 }}>
                <h5>Quote Options</h5>
                <div className="dialog--input" style={{ marginBottom: 0 }}>
                  <label>
                    <Checkbox
                      id="includeSignature"
                      label="Include signature on quotes?"
                      checked={this.state.includeSignature}
                      color="primary"
                      onChange={this.handleCheckboxChange}
                    />
                    <span className="checkbox-label">
                      Include signature on quotes?
                    </span>
                  </label>
                </div>
                {this.state.includeSignature && (
                  <div className="dialog--input">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="filled"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="signatureDate"
                        label="Signature Date"
                        value={this.state.signatureDate}
                        onChange={e =>
                          this.handleDateChange(e, "signatureDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                )}
              </div>
            )}
            <h5 style={{ marginBottom: 12 }}>Contract Details</h5>
            <div className="dialog--input">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="filled"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="serviceTermStartDate"
                  label="Contract Start Date"
                  value={this.state.serviceTermStartDate}
                  onChange={e =>
                    this.handleDateChange(e, "serviceTermStartDate")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="dialog--input">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="filled"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="serviceTermEndDate"
                  label="Contract End Date"
                  value={this.state.serviceTermEndDate}
                  onChange={e => this.handleDateChange(e, "serviceTermEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="dialog--input">
              <TextField
                id="purchaseOrder"
                label="Purchase Order"
                variant="filled"
                type="email"
                onChange={this.handleChange}
                defaultValue={singleRow.purchaseOrder}
              ></TextField>
            </div>
            <div className="dialog--input">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="filled"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="closeDate"
                  label="Expected Close Date"
                  value={this.state.closeDate}
                  onChange={e => this.handleDateChange(e, "closeDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">Contract Type *</InputLabel>
                <Select
                  id="contractType"
                  labelId="contractType-label"
                  variant="filled"
                  label="Contract Type"
                  required
                  value={this.state.contractType}
                  onChange={this.handleSelectChange("contractType")}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Renewal">Renewal</MenuItem>
                </Select>
              </FormControl>
            </div>

            <TextField
              id="notes"
              label="Notes"
              variant="filled"
              multiline={true}
              rows={5}
              rowsMax={5}
              onChange={this.handleChange}
              defaultValue={singleRow.notes}
            ></TextField>
          </div>
          <div className="update--column">
            <h2>Internal Information</h2>
            {profile.permission === "Admin" && (
              <div>
                <h5>Ordered By</h5>
                <p>{singleRow.userName}</p>
                {singleRow.userRegion && (
                  <div>
                    <h5>Region</h5>
                    <p>{singleRow.userRegion}</p>
                  </div>
                )}
              </div>
            )}
            <div>
              <h5>Quote Value</h5>
              <ul>
                {quoteValues.map(item => (
                  <li>
                    {item.value} - {item.label}
                  </li>
                ))}
              </ul>
            </div>

            {profile.permission === "Admin" && singleRow.status !== "Open" && (
              <div className="dialog--input">
                <TextField
                  id="valueOverride"
                  label="Total Contract Value Override"
                  type="number"
                  variant="filled"
                  defaultValue={singleRow.valueOverride}
                  onChange={this.handleChange}
                />
              </div>
            )}

            <div className="dialog--input">
              <TextField
                id="discount"
                label="Discount %"
                type="number"
                variant="filled"
                defaultValue={this.state.discount}
                onChange={this.handleChange}
              />
            </div>
            <div className="dialog--input">
              <TextField
                id="multiplier"
                label="Multiplier"
                type="number"
                variant="filled"
                defaultValue={this.state.multiplier}
                onChange={this.handleChange}
              />
            </div>

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="customerType-label">Customer Type</InputLabel>
                <Select
                  id="customerType"
                  labelId="customerType-label"
                  variant="filled"
                  label="Customer Type"
                  value={this.state.customerType}
                  onChange={this.handleSelectChange("customerType")}
                >
                  <MenuItem value="Hospital">Hospital</MenuItem>
                  <MenuItem value="Surgery Center">Surgery Center</MenuItem>
                  <MenuItem value="Clinic">Clinic</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="customerStatus-label">
                  Customer Status
                </InputLabel>
                <Select
                  id="customerStatus"
                  labelId="customerStatus-label"
                  variant="filled"
                  label="Customer Status"
                  value={this.state.customerStatus}
                  onChange={this.handleSelectChange("customerStatus")}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Existing">Existing</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="managedBy-label">Managed By</InputLabel>
                <Select
                  id="managedBy"
                  labelId="managedBy-label"
                  variant="filled"
                  label="Managed By"
                  value={this.state.managedBy}
                  onChange={this.handleSelectChange("managedBy")}
                >
                  <MenuItem value="New - Rep">New - Rep</MenuItem>
                  <MenuItem value="New - Dept">New - Dept</MenuItem>
                  <MenuItem value="Renewal - Rep">Renewal - Rep</MenuItem>
                  <MenuItem value="Renewal - Dept">Renewal - Dept</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="currentContract-label">
                  Is this customer currently under contract?
                </InputLabel>
                <Select
                  id="currentContract"
                  labelId="currentContract-label"
                  variant="filled"
                  label="Current Contract"
                  required
                  value={this.state.currentContract}
                  onChange={this.handleSelectChange("currentContract")}
                >
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            {this.state.currentContract === "Yes" && (
              <div className="dialog--input">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="filled"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="currentContractExpiration"
                    label="Current Contract Expiration"
                    value={this.state.currentContractExpiration}
                    onChange={e =>
                      this.handleDateChange(e, "currentContractExpiration")
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            )}

            <div className="dialog--input">
              <FormControl variant="filled">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  id="status"
                  labelId="status-label"
                  variant="filled"
                  label="Status"
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed - Won">Closed - Won</MenuItem>
                  <MenuItem value="Closed - Lost">Closed - Lost</MenuItem>
                  <MenuItem value="Closed - Other Option Selected">
                    Closed - Other Option Selected
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {this.state.status === "Closed - Won" && (
              <div className="dialog--input">
                <FormControl variant="filled">
                  <InputLabel id="status-label">Coverage Type</InputLabel>

                  <Select
                    id="status"
                    labelId="status-label"
                    variant="filled"
                    label="Coverage level"
                    value={this.state.closedStatus}
                    onChange={this.handleSelectChange("closedStatus")}
                  >
                    <MenuItem value="Labor Only">Labor Only</MenuItem>
                    <MenuItem value="Parts And Labor">Parts And Labor</MenuItem>
                    <MenuItem value="Full Service">Full Service</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <TextField
              id="notes"
              label="Internal Notes"
              variant="filled"
              multiline={true}
              rows={5}
              rowsMax={5}
              onChange={this.handleChange}
              defaultValue={singleRow.internalNotes}
            ></TextField>
          </div>
        </div>

        <div className="update" style={{ paddingTop: 0 }}>
          <div className="update--column" style={{ width: "100%" }}>
            <h2>Equipment</h2>
            <form>
              {quoteprices && (
                <div className="equipment">
                  {this.state.equipment.map((row, i) => (
                    <div className="equipment--selects" key={i}>
                      <div className="field">
                        <FormControl variant="filled">
                          <InputLabel id="make-label">
                            Equipment Type *
                          </InputLabel>
                          <Select
                            id="equipmentType"
                            labelId="make-label"
                            variant="filled"
                            label="equipmentType"
                            required
                            value={row.equipmentType}
                            onChange={this.handleEquipmentTypeChange(i)}
                          >
                            {renderTypeList()}
                          </Select>
                        </FormControl>
                      </div>

                      {row.equipmentType === "Custom" ? (
                        <>
                          <div className="field">
                            <TextField
                              id="customName"
                              label="Custom Equipment Name"
                              type="text"
                              variant="filled"
                              required
                              value={row.customName}
                              onChange={this.handleEquipmentTextChange(i)}
                            />
                          </div>
                          <div className="field">
                            <TextField
                              id="customCost"
                              label="Cost Per Service"
                              type="number"
                              variant="filled"
                              value={row.customCost}
                              min="1"
                              required
                              onChange={this.handleEquipmentTextChange(i)}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="field">
                            <FormControl variant="filled">
                              <InputLabel id="make-label">
                                Manufacturer *
                              </InputLabel>
                              <Select
                                id="make"
                                labelId="make-label"
                                variant="filled"
                                label="make"
                                required
                                value={row.make}
                                onChange={this.handleEquipmentManufacturerChange(
                                  i
                                )}
                              >
                                {renderManufacturerList(row.equipmentType)}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="field">
                            <FormControl variant="filled">
                              <InputLabel id="model-label">Model *</InputLabel>
                              <Select
                                id="model"
                                labelId="model-label"
                                variant="filled"
                                label="model"
                                required
                                value={row.model}
                                onChange={this.handleEquipmentModelChange(i)}
                              >
                                {renderModelList(
                                  row.equipmentType,
                                  row.manufacturer
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      )}

                      <div className="field">
                        <FormControl variant="filled">
                          <InputLabel id="frequency-label">
                            Service Frequency *
                          </InputLabel>
                          <Select
                            id="equipmentFrequency"
                            labelId="frequency-label"
                            variant="filled"
                            label="equipmentFrequency"
                            required
                            value={row.frequency}
                            onChange={this.handleEquipmentFrequencyChange(i)}
                          >
                            <MenuItem value="BiMonthly">
                              Bi-monthly (6 times per year)
                            </MenuItem>
                            <MenuItem value="Quarterly">
                              Quarterly (4 times per year)
                            </MenuItem>
                            <MenuItem value="SemiAnnual">
                              Semi-annually (2 times per year)
                            </MenuItem>
                            <MenuItem value="Annual">
                              Annually (1 time per year)
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="field">
                        <TextField
                          id="serial"
                          label="Serial #"
                          type="text"
                          variant="filled"
                          value={row.serial}
                          min="1"
                          onChange={this.handleEquipmentTextChange(i)}
                        />
                      </div>
                      <div className="field">
                        <TextField
                          id="location"
                          label="Location (Room)"
                          type="text"
                          variant="filled"
                          value={row.location}
                          onChange={this.handleEquipmentTextChange(i)}
                        />
                      </div>
                      <div className="field actions">
                        <Button
                          className="equipment--add"
                          variant="contained"
                          color="primary"
                          onClick={this.addEquipmentRow(i)}
                        >
                          <img src={AddIcon} alt="icon" />
                        </Button>
                        <Button
                          className="equipment--duplicate"
                          variant="contained"
                          color="primary"
                          onClick={this.duplicateEquipmentRow(i)}
                        >
                          <img src={CopyIcon} alt="icon" />
                        </Button>
                        {i > 0 && (
                          <Button
                            className="equipment--remove"
                            variant="contained"
                            color="primary"
                            onClick={this.removeEquipmentRow(i)}
                          >
                            <img src={RemoveIcon} alt="icon" />
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </form>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    quoteprices: state.firestore.ordered.quoteprices
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateQuote: data => dispatch(updateQuote(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "quoteprices",
      orderBy: ["manufacturer", "asc"]
    }
  ])
)(QuoteDialog);
