const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_USER_SUCCESS":
      return {
        ...state,
        authError: null,
        newUser: true
      };
    case "CREATE_USER_ERROR":
      return {
        ...state,
        authError: action.err.message,
        newUser: false
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        newUser: false
      };
    case "DISABLE_USER_SUCCESS":
      console.log("DISABLE_USER_SUCCESS called");
      return {
        ...state,
        disableUserSuccess: true
      };
    case "DISABLE_USER_ERROR":
      console.log("DISABLE_USER_ERROR called");
      return {
        ...state,
        disableUserSuccess: false
      };
    case "ENABLE_USER_SUCCESS":
      console.log("ENABLE_USER_SUCCESS called");
      return {
        ...state,
        enableUserSuccess: true
      };
    case "ENABLE_USER_ERROR":
      console.log("ENABLE_USER_ERROR called");
      return {
        ...state,
        enableUserSuccess: false
      };
    default:
      return state;
  }
};

export default authReducer;
