import React from "react";

// Components
import FileListTableRow from "./FileListTableRow";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

const FileListTable = ({ rowTitle, collection, rows, permission, profile }) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{rowTitle}</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <FileListTableRow
                singleRow={row}
                key={row.id}
                rowId={row.id}
                collection={collection}
                permission={permission}
                profile={profile}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FileListTable;
