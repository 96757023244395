import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./css/app.css";

// Components
import Navbar from "./components/nav/Navbar";

// Main Pages
import home from "./pages/home";
import login from "./pages/login";
import reset from "./pages/reset";

// Users
import usersManage from "./pages/users/usersManage";
import usersCreate from "./pages/users/usersCreate";

// Quotes
import quoteRequest from "./pages/quote/quoteRequest";
import quoteOpen from "./pages/quote/quoteOpen";
import quoteClosed from "./pages/quote/quoteClosed";
import quoteDeleted from "./pages/quote/quoteDeleted";
import quoteCsv from "./pages/quote/quoteCsv";
import quoteSearch from "./pages/quote/quoteSearch";
import quoteReportRequest from "./pages/quote/quoteReportRequest";

// Documents
import documentsView from "./pages/documents/documentsView";
import documentsManage from "./pages/documents/documentsManage";
import documentsAdd from "./pages/documents/documentsAdd";

// Pricing
import pricingManage from "./pages/pricing/pricingManage";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <div className="content">
          <Switch>
            {/* Auth */}
            <Route exact path="/" component={home} />
            <Route exact path="/login" component={login} />
            <Route exact path="/reset" component={reset} />
            {/* Users */}
            <Route exact path="/users/manage" component={usersManage} />
            <Route exact path="/users/create" component={usersCreate} />
            {/* Quotes */}
            <Route exact path="/quote/request" component={quoteRequest} />
            <Route exact path="/quote/open" component={quoteOpen} />
            <Route exact path="/quote/closed" component={quoteClosed} />
            <Route exact path="/quote/deleted" component={quoteDeleted} />
            <Route exact path="/quote/csv" component={quoteCsv} />
            <Route exact path="/quote/search" component={quoteSearch} />
            <Route exact path="/quote/report" component={quoteReportRequest} />
            {/* Documents */}
            <Route exact path="/documents/view" component={documentsView} />
            <Route exact path="/documents/manage" component={documentsManage} />
            <Route exact path="/documents/add" component={documentsAdd} />
            {/* Prices */}
            <Route exact path="/pricing/manage" component={pricingManage} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
