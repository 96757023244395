import React, { Component } from "react";

// Components
import RemoveDialog from "./RemoveDialog";
import SendDialog from "./SendDialog";

// MUI Stuff
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Images
import RemoveIcon from "../../img/icon-circle-x.svg";
import MailIcon from "../../img/icon-write.svg";

class FileListTableRow extends Component {
  state = {
    removeOpen: false,
    sendOpen: false
  };
  handleSend = () => {
    this.setState({ sendOpen: true });
  };
  handleSendClose = () => {
    this.setState({ sendOpen: false });
  };
  handleRemove = () => {
    this.setState({ removeOpen: true });
  };
  handleRemoveClose = () => {
    this.setState({ removeOpen: false });
  };
  render() {
    const { singleRow, rowId, collection, permission, profile } = this.props;
    return (
      <TableRow>
        <TableCell>
          <Button className="file" href={singleRow.fileURL} target="_blank">
            {singleRow.name}
          </Button>
        </TableCell>

        <TableCell className="actions">
          <Button
            className="send"
            variant="contained"
            color="primary"
            onClick={this.handleSend}
          >
            <img src={MailIcon} alt="icon" />
            Share
          </Button>
          {permission === "Admin" && (
            <Button
              className="remove"
              variant="contained"
              color="primary"
              onClick={this.handleRemove}
            >
              <img src={RemoveIcon} alt="icon" />
              Remove
            </Button>
          )}
        </TableCell>

        <SendDialog
          open={this.state.sendOpen}
          handleClose={this.handleSendClose}
          collection={collection}
          docId={rowId}
          docData={singleRow}
          profile={profile}
        />

        {permission === "Admin" && (
          <RemoveDialog
            open={this.state.removeOpen}
            handleClose={this.handleRemoveClose}
            collection={collection}
            docId={rowId}
            name={singleRow.name}
          />
        )}
      </TableRow>
    );
  }
}

export default FileListTableRow;
