import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { quoteReport } from "../../store/actions/quoteActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

class quoteReportRequest extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    status: "Open",
    email: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };
  handleStartDateChange = date => {
    this.setState({
      startDate: new Date(date)
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: new Date(date)
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.quoteReport(this.state);
    // this.props.history.push("/service/sra/active");
  };
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/login" />;
    return (
      <div>
        <h1>Request Quote Report</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="field half">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="startDate"
                label="Start Date *"
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="field half">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="closeDate"
                label="End Date *"
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="field half">
            <FormControl variant="filled">
              <InputLabel id="priority-label">Status *</InputLabel>
              <Select
                id="status"
                labelId="status-label"
                variant="filled"
                label="Status"
                value={this.state.status}
                required
                onChange={this.handleSelectChange("status")}
              >
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Closed - Won">Closed - Won</MenuItem>
                <MenuItem value="Closed - Lost">Closed - Lost</MenuItem>
                <MenuItem value="Closed - Other Option Selected">
                  Closed - Other Option Selected
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="field half">
            <TextField
              id="email"
              label="Email Report to"
              type="email"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <Button type="submit" variant="contained" color="primary">
              Submit Request
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    quoteReport: data => dispatch(quoteReport(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(quoteReportRequest);
