import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { updatePricingRow } from "../../../store/actions/pricingActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class PricingDialog extends Component {
  state = {
    id: this.props.rowId,
    manufacturer: this.props.singleRow.manufacturer,
    equipmentType: this.props.singleRow.equipmentType,
    model: this.props.singleRow.model,
    laborBiMonthly: this.props.singleRow.laborBiMonthly,
    laborQuarterly: this.props.singleRow.laborQuarterly,
    laborSemiAnnual: this.props.singleRow.laborSemiAnnual,
    laborAnnual: this.props.singleRow.laborAnnual,
    partsAndLaborBiMonthly: this.props.singleRow.partsAndLaborBiMonthly,
    partsAndLaborQuarterly: this.props.singleRow.partsAndLaborQuarterly,
    partsAndLaborSemiAnnual: this.props.singleRow.partsAndLaborSemiAnnual,
    partsAndLaborAnnual: this.props.singleRow.partsAndLaborAnnual,
    fullBiMonthly: this.props.singleRow.fullBiMonthly,
    fullQuarterly: this.props.singleRow.fullQuarterly,
    fullSemiAnnual: this.props.singleRow.fullSemiAnnual,
    fullAnnual: this.props.singleRow.fullAnnual
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSave = () => {
    this.props.updatePricingRow(this.state);
    this.props.handleClose();
  };

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        className="dialog--edit"
      >
        <AppBar className="modal-appbar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Edit Pricing</Typography>
            <Button autoFocus color="inherit" onClick={this.handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>

        <div className="update">
          <div className="update--column">
            <h2>Equipment Information</h2>
            <div className="dialog--input">
              <TextField
                id="manufacturer"
                label="Manufacturer"
                variant="filled"
                onChange={this.handleChange}
                value={this.state.manufacturer}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="equipmentType"
                label="Equipment Type"
                variant="filled"
                onChange={this.handleChange}
                value={this.state.equipmentType}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="model"
                label="Model"
                variant="filled"
                onChange={this.handleChange}
                value={this.state.model}
              ></TextField>
            </div>
          </div>

          <div className="update--column">
            <h2>Labor Only</h2>
            <div className="dialog--input">
              <TextField
                id="laborBiMonthly"
                label="Bi-monthly"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.laborBiMonthly}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="laborQuarterly"
                label="Quarterly"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.laborQuarterly}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="laborSemiAnnual"
                label="Semi-annual"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.laborSemiAnnual}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="laborAnnual"
                label="Annual"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.laborAnnual}
              ></TextField>
            </div>
          </div>

          <div className="update--column">
            <h2>Parts & Labor</h2>
            <div className="dialog--input">
              <TextField
                id="partsAndLaborBiMonthly"
                label="Bi-monthly"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.partsAndLaborBiMonthly}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="partsAndLaborQuarterly"
                label="Quarterly"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.partsAndLaborQuarterly}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="partsAndLaborSemiAnnual"
                label="Semi-annual"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.partsAndLaborSemiAnnual}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="partsAndLaborAnnual"
                label="Annual"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.partsAndLaborAnnual}
              ></TextField>
            </div>
          </div>

          <div className="update--column">
            <h2>Full Service</h2>
            <div className="dialog--input">
              <TextField
                id="fullBiMonthly"
                label="Bi-monthly"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.fullBiMonthly}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="fullQuarterly"
                label="Quarterly"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.fullQuarterly}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="fullSemiAnnual"
                label="Semi-annual"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.fullSemiAnnual}
              ></TextField>
            </div>
            <div className="dialog--input">
              <TextField
                id="fullAnnual"
                label="Annual"
                variant="filled"
                type="number"
                onChange={this.handleChange}
                value={this.state.fullAnnual}
              ></TextField>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePricingRow: data => dispatch(updatePricingRow(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingDialog);
