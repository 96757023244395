import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "react-redux-firebase";

// Components
import LoadingBlock from "../global/LoadingBlock";
import QuoteTable from "./components/QuoteTable";
import QuoteTabs from "./components/QuoteTabs";
import QuoteReportButtons from "./components/QuoteReportButtons";

class quoteOpen extends Component {
  state = {
    perPage: 300
  };
  showMore = current => {
    let more = Number(current) + 300;
    this.setState({ perPage: more });
  };
  render() {
    const { auth, profile } = this.props;
    // If user isn't logged in
    if (isEmpty(auth)) {
      return <Redirect to="/login" />;
    }
    // If everything went to plan
    if (profile.isLoaded) {
      let firestoreQuery;
      if (profile.permission === "Service Rep") {
        firestoreQuery = [
          ["user", "==", auth.uid],
          ["status", "==", "Open"]
        ];
      }
      if (profile.permission === "Manager") {
        firestoreQuery = [
          ["userRegion", "==", profile.region],
          ["status", "==", "Open"]
        ];
      }
      if (profile.permission === "Admin") {
        firestoreQuery = [["status", "==", "Open"]];
      }
      return (
        <div>
          <h1>View Service Contract Quotes</h1>
          <QuoteReportButtons permission={profile.permission} />
          <QuoteTable
            perPage={this.state.perPage}
            showMore={this.showMore}
            permission={profile.permission}
            firestoreQuery={firestoreQuery}
          />
          <QuoteTabs />
        </div>
      );
    }
    // If profile and auth aren't loaded
    return <LoadingBlock />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, null)(quoteOpen);
