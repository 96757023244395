const initState = {
  pricingUpdate: false,
  pricingError: ""
};

const pricingReducer = (state = initState, action) => {
  switch (action.type) {
    case "SAVE_PRICING":
      return {
        ...state,
        pricingUpdate: !state.pricingUpdate,
        pricingError: null
      };
    case "SAVE_PRICING_ERROR":
      return {
        ...state,
        pricingError: "Error saving pricing. Please try again."
      };
    case "UPDATE_PRICING":
      return {
        ...state,
        pricingUpdate: !state.pricingUpdate,
        pricingError: null
      };
    case "UPDATE_PRICING_ERROR":
      return {
        ...state,
        pricingError: "Error saving pricing. Please try again."
      };
    default:
      return state;
  }
};

export default pricingReducer;
