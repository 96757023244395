const initState = {
  documentSent: false,
  documentError: null
};

const documentReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_DOCUMENT":
      return state;
    case "DOCUMENT_SEND_SUCCESS":
      return {
        ...state,
        documentSent: !state.documentSent,
        documentError: null
      };
    case "DOCUMENT_SEND_ERROR":
      return {
        ...state,
        documentError: "Error sending this document. Please try again."
      };
    default:
      return state;
  }
};

export default documentReducer;
