// Requirements
const axios = require("axios");

export const createDocument = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    firestore
      .collection("documents")
      .add({
        name: data.name,
        fileURL: data.fileURL
      })
      .then(() => {
        dispatch({
          type: "CREATE_DOCUMENT",
          documents: data
        });
      });
  };
};

export const sendFile = data => {
  return (dispatch, getState, getFirebase) => {
    axios
      .post(
        "https://us-central1-pmm-app-10dbb.cloudfunctions.net/sendFile",
        data
      )
      .then(res => {
        dispatch({
          type: "DOCUMENT_SEND_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({
          type: "DOCUMENT_SEND_ERROR"
        });
      });
  };
};
