import React, { Component } from "react";

// Components
import PricingDialog from "./PricingDialog";
import RemoveDialog from "../../global/RemoveDialog";

// MUI Stuff
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Images
import UpdateIcon from "../../../img/icon-timer.svg";
import RemoveIcon from "../../../img/icon-circle-x.svg";

class PricingTableRow extends Component {
  state = {
    removeOpen: false,
    viewOpen: false
  };

  handleRemove = () => {
    this.setState({ removeOpen: true });
  };
  handleRemoveClose = () => {
    this.setState({ removeOpen: false });
  };

  handleUpdate = () => {
    this.setState({ viewOpen: true });
  };
  handleUpdateClose = () => {
    this.setState({ viewOpen: false });
  };

  render() {
    const { singleRow, rowId, collection } = this.props;

    return (
      <TableRow>
        <TableCell>{singleRow.manufacturer}</TableCell>
        <TableCell>{singleRow.equipmentType}</TableCell>
        <TableCell>{singleRow.model}</TableCell>
        <TableCell className="actions">
          <Button
            className="update"
            variant="contained"
            color="primary"
            onClick={this.handleUpdate}
          >
            <img src={UpdateIcon} alt="icon" />
            Update
          </Button>
          <Button
            className="remove"
            variant="contained"
            color="primary"
            onClick={this.handleRemove}
          >
            <img src={RemoveIcon} alt="icon" />
            Remove
          </Button>
        </TableCell>
        <PricingDialog
          singleRow={singleRow}
          rowId={rowId}
          open={this.state.viewOpen}
          handleClose={this.handleUpdateClose}
          collection={collection}
          key={rowId}
        />
        <RemoveDialog
          open={this.state.removeOpen}
          handleClose={this.handleRemoveClose}
          docId={rowId}
          name={
            "the pricing row for " +
            singleRow.manufacturer +
            " " +
            singleRow.model
          }
          collection={collection}
        />
      </TableRow>
    );
  }
}

export default PricingTableRow;
