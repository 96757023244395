export const removeDocument = (docId, collection) => {
  if (collection === "quotes") {
    return (dispatch, getState, getFirebase) => {
      let toUpdate = {
        deleted: true
      };

      const firestore = getFirebase().firestore();
      firestore
        .collection(collection)
        .doc(docId)
        .update(toUpdate)
        .then(() => {
          dispatch({
            type: "DOCUMENT_REMOVED"
          });
        });
    };
  } else {
    return (dispatch, getState, getFirebase) => {
      const firestore = getFirebase().firestore();
      firestore
        .collection(collection)
        .doc(docId)
        .delete()
        .then(() => {
          dispatch({
            type: "DOCUMENT_REMOVED"
          });
        });
    };
  }
};

export const restoreDocument = (docId, collection) => {
  return (dispatch, getState, getFirebase) => {
    let toUpdate = {
      deleted: false
    };

    const firestore = getFirebase().firestore();
    firestore
      .collection(collection)
      .doc(docId)
      .update(toUpdate)
      .then(() => {
        dispatch({
          type: "DOCUMENT_RESTORED"
        });
      });
  };
};
