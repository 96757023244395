import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyAvWQF8eccDlbMyk_2Cyl51nPTFJQ-3geo",
  authDomain: "pmm-app-10dbb.firebaseapp.com",
  projectId: "pmm-app-10dbb",
  storageBucket: "pmm-app-10dbb.appspot.com",
  messagingSenderId: "409165659573",
  appId: "1:409165659573:web:0cd9274a1382455161adfc"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

// Config for emulator
// if (window.location.hostname === "localhost") {
//   db.settings({ host: "localhost:5002", ssl: false });
// }

export default firebase;
