exports.reformatTimestamp = inputDate => {
  let date = new Date(inputDate * 1000);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let ampm = hours >= 12 ? "pm" : "am";
  if (hours > 12) {
    hours = hours - 12;
  }
  let minutes = "0" + date.getMinutes();
  let formattedDate =
    month +
    "/" +
    day +
    "/" +
    year +
    " at " +
    hours +
    ":" +
    minutes.substr(-2) +
    ampm;
  return formattedDate;
};

exports.reformatDate = inputDate => {
  let date = new Date(inputDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let formattedDate = month + "/" + day + "/" + year;
  return formattedDate;
};

exports.formatUSD = input => {
  let commaNumber = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let formattedUSD = "$" + commaNumber;
  return formattedUSD;
};

exports.formatGBP = input => {
  let inputNumber = Number(input);
  let roundedNumber = inputNumber.toFixed(2);
  let commaNumber = roundedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let formattedGBP = "£" + commaNumber;
  return formattedGBP;
};

exports.invertDirection = input => {
  if (input === "asc") {
    return "desc";
  } else {
    return "asc";
  }
};

exports.getLink = (type, permission) => {
  if (type === "Users") {
    return "/users/manage";
  }
  if (type === "Quote") {
    if (permission === "Admin" || permission === "Manager") {
      return "/quote/open";
    } else {
      return "/quote/request";
    }
  }
  if (type === "Documents") {
    if (permission === "Admin" || permission === "Manager") {
      return "/documents/manage";
    } else {
      return "/documents/view";
    }
  }

  return "#";
};
