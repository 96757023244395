import React from "react";

// Components
import PricingTableRow from "./PricingTableRow";
import PricingTableAddRow from "./PricingTableAddRow";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

const PricingTable = ({ quoteprices }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Manufacturer</TableCell>
            <TableCell>Equipment Type</TableCell>
            <TableCell>Make</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quoteprices.map((price, index) => {
            return (
              <PricingTableRow
                singleRow={price}
                key={index}
                rowId={price.id}
                collection="quoteprices"
              />
            );
          })}
          <PricingTableAddRow collection="quoteprices" />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;
