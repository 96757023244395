import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";

// Components
import LoadingBlock from "../global/LoadingBlock";
import FileListTable from "../global/FileListTable";

const documentsView = ({ auth, profile, documents }) => {
  // Redirect if logged out
  if (isEmpty(auth)) {
    return <Redirect to="/login" />;
  }

  // Everything has loaded
  if (isLoaded(profile) && isLoaded(documents)) {
    return (
      <div>
        <h1>Documents</h1>
        <FileListTable
          rowTitle="Document Name"
          rows={documents}
          permission={profile.permission}
        />
      </div>
    );
  }

  // While Loading
  return <LoadingBlock />;
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    documents: state.firestore.ordered.documents
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "documents",
      orderBy: ["name", "asc"]
    }
  ])
)(documentsView);
