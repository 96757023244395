import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { invertDirection } from "../../../util/functions";

// Components
import LoadingBlock from "../../global/LoadingBlock";
import QuoteTableRowDeleted from "./QuoteTableRowDeleted";

// MUI Stuff
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

class QuoteTableDeleted extends Component {
  state = {
    columnToSort: "date",
    sortDirection: "desc"
  };
  handleShowMore = () => {
    this.props.showMore(this.props.perPage);
  };
  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection(state.sortDirection)
          : "asc"
    }));
  };
  render() {
    const { quotes, permission } = this.props;

    const orderedQuotes = _.orderBy(
      quotes,
      [this.state.columnToSort],
      [this.state.sortDirection]
    );

    return (
      <div>
        {!isLoaded(quotes) ? (
          <LoadingBlock />
        ) : isEmpty(quotes) ? (
          <h2>No Quotes Found</h2>
        ) : (
          <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("serviceFacilityName")}
                      >
                        Facility
                      </TableSortLabel>
                    </TableCell>
                    {permission === "Service Rep" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("facilityContactName")}
                        >
                          Facility Contact
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>
                      <TableSortLabel
                        direction={this.state.sortDirection}
                        onClick={() => this.handleSort("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {permission !== "Service Rep" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userName")}
                        >
                          Ordered By
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {permission === "Admin" && (
                      <TableCell>
                        <TableSortLabel
                          direction={this.state.sortDirection}
                          onClick={() => this.handleSort("userRegion")}
                        >
                          Region
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedQuotes.map((quote, index) => (
                    <QuoteTableRowDeleted
                      singleRow={quote}
                      key={quote.id}
                      rowId={quote.id}
                      collection="quotes"
                      permission={permission}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {quotes.length % 50 === 0 && (
              <Button
                className="show-more"
                variant="contained"
                color="primary"
                onClick={this.handleShowMore}
              >
                Show Next 50 Quotes
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    quotes: state.firestore.ordered.quotes
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "quotes",
        where: props.firestoreQuery,
        limit: props.perPage,
        orderBy: ["date", "desc"]
      }
    ];
  })
)(QuoteTableDeleted);
