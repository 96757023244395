import React from "react";
import { NavLink } from "react-router-dom";

const QuoteReportButtons = () => {
  return (
    <div className="buttons">
      <NavLink to="/quote/search" activeClassName="active">
        Search Quotes
      </NavLink>
    </div>
  );
};

export default QuoteReportButtons;
