import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { updateUser } from "../../../store/actions/userActions";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UsersDialog extends Component {
  state = {
    id: this.props.singleRow.id,
    name: this.props.singleRow.name,
    email: this.props.singleRow.email,
    permission: this.props.singleRow.permission,
    region: this.props.singleRow.region,
    address: this.props.singleRow.address,
    city: this.props.singleRow.city,
    state: this.props.singleRow.state,
    zip: this.props.singleRow.zip,
    pricingaccess: this.props.singleRow.pricingaccess
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };
  handleSave = () => {
    this.props.updateUser(this.state);
    this.props.handleClose();
  };
  handleClose = () => {
    this.props.handleClose();
  };
  render() {
    const { singleRow } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        className="dialog--edit users"
      >
        <AppBar className="modal-appbar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{singleRow.name}</Typography>
            <Button autoFocus color="inherit" onClick={this.handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div className="update">
          <div className="update--column">
            <h2>User Information</h2>
            <TextField
              id="name"
              label="Name"
              type="text"
              variant="filled"
              defaultValue={singleRow.name}
              onChange={this.handleChange}
            />
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="filled"
              disabled={true}
              defaultValue={singleRow.email}
              onChange={this.handleChange}
            />
            <FormControl variant="filled">
              <InputLabel id="permission-label">Permission</InputLabel>
              <Select
                id="permission"
                labelId="permission-label"
                variant="filled"
                label="Permission"
                value={this.state.permission}
                onChange={this.handleSelectChange("permission")}
              >
                <MenuItem value="Rep">Rep</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </FormControl>
            {this.state.permission !== "Admin" && (
              <FormControl variant="filled">
                <InputLabel id="region-label">Region</InputLabel>
                <Select
                  id="region"
                  labelId="region-label"
                  variant="filled"
                  label="Region"
                  value={this.state.region}
                  required
                  onChange={this.handleSelectChange("region")}
                >
                  <MenuItem value="Midwest">Midwest</MenuItem>
                  <MenuItem value="Northeast">Northeast</MenuItem>
                  <MenuItem value="Southeast">Southeast</MenuItem>
                  <MenuItem value="South">South</MenuItem>
                  <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                  <MenuItem value="West">West</MenuItem>
                </Select>
              </FormControl>
            )}
            {this.state.permission === "Admin" && (
              <label>
                <Checkbox
                  id="pricingaccess"
                  label="Give user access to manage pricing?"
                  defaultChecked={this.state.pricingaccess}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">
                  Give user access to manage pricing?
                </span>
              </label>
            )}
          </div>
          {this.state.permission === "Rep" && (
            <div className="update--column">
              <h2>Contact Information</h2>
              <div>
                <TextField
                  id="address"
                  label="Address"
                  type="text"
                  variant="filled"
                  defaultValue={singleRow.address}
                  onChange={this.handleChange}
                />
                <TextField
                  id="city"
                  label="City"
                  type="text"
                  variant="filled"
                  defaultValue={singleRow.city}
                  onChange={this.handleChange}
                />
                <FormControl variant="filled">
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    id="state"
                    labelId="state-label"
                    variant="filled"
                    label="State"
                    value={singleRow.state}
                    onChange={this.handleSelectChange("state")}
                  >
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="zip"
                  label="Zip"
                  type="text"
                  variant="filled"
                  defaultValue={singleRow.zip}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: user => dispatch(updateUser(user))
  };
};

export default connect(null, mapDispatchToProps)(UsersDialog);
