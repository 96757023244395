const initState = {};

const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case "DOCUMENT_REMOVED":
      console.log("Record Removed from Firestore");
      return state;

    case "DOCUMENT_RESTORED":
      console.log("Record Restored from Firestore");
      return state;

    default:
      return state;
  }
};

export default globalReducer;
