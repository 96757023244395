import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Actions
import { createUser } from "../../store/actions/userActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// Components
import UsersTabs from "./components/UsersTabs";

class usersCreate extends Component {
  state = {
    email: "",
    name: "",
    permission: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    region: "",
    pricingaccess: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.newUser !== prevProps.newUser) {
      window.location.href = "/users/manage";
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createUser(this.state);
  };

  render() {
    const { authError, auth } = this.props;

    if (!auth.uid) return <Redirect to="/login" />;

    return (
      <div>
        <UsersTabs />
        <h1>Create User</h1>
        {authError && (
          <div className="error-message">
            <p>{authError}</p>
          </div>
        )}
        <form onSubmit={this.handleSubmit}>
          <div className="field">
            <TextField
              id="name"
              label="Name"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="email"
              label="Email Address"
              type="email"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <TextField
              id="password"
              label="Password"
              type="password"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <FormControl variant="filled">
              <InputLabel id="permission-label">Permission *</InputLabel>
              <Select
                id="permission"
                labelId="permission-label"
                variant="filled"
                label="Permission"
                value={this.state.permission}
                required
                onChange={this.handleSelectChange("permission")}
              >
                <MenuItem value="Rep">Rep</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </FormControl>
          </div>
          {this.state.permission === "Rep" && (
            <div className="toggle">
              <div className="field">
                <h2>Rep Address</h2>
              </div>
              <div className="field">
                <TextField
                  id="address"
                  label="Home Address"
                  type="text"
                  variant="filled"
                  required
                  onChange={this.handleChange}
                />
              </div>
              <div className="field half">
                <TextField
                  id="city"
                  label="City"
                  type="text"
                  variant="filled"
                  required
                  onChange={this.handleChange}
                />
              </div>
              <div className="field quarter">
                <FormControl variant="filled">
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    id="state"
                    labelId="state-label"
                    variant="filled"
                    label="State"
                    value={this.state.state}
                    required
                    onChange={this.handleSelectChange("state")}
                  >
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="field quarter">
                <TextField
                  id="zip"
                  label="Zip"
                  type="text"
                  variant="filled"
                  required
                  onChange={this.handleChange}
                />
              </div>
            </div>
          )}
          {(this.state.permission === "Manager" ||
            this.state.permission === "Rep") && (
            <div className="field half">
              <FormControl variant="filled">
                <InputLabel id="regions-label">Region *</InputLabel>
                <Select
                  id="regions"
                  labelId="region-label"
                  variant="filled"
                  label="Region"
                  value={this.state.region}
                  required
                  onChange={this.handleSelectChange("region")}
                >
                  <MenuItem value="Midwest">Midwest</MenuItem>
                  <MenuItem value="Northeast">Northeast</MenuItem>
                  <MenuItem value="Southeast">Southeast</MenuItem>
                  <MenuItem value="South">South</MenuItem>
                  <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                  <MenuItem value="West">West</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {this.state.permission === "Admin" && (
            <div className="field half">
              <label>
                <Checkbox
                  id="pricingaccess"
                  label="Give user access to manage pricing?"
                  defaultChecked={this.state.pricingaccess}
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-label">
                  Give user access to manage pricing?
                </span>
              </label>
            </div>
          )}
          <div className="field">
            <Button type="submit" variant="contained" color="primary">
              Add User
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    newUser: state.auth.newUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: user => dispatch(createUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(usersCreate);
