export const savePricing = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    // Remove all quote prices
    firestore
      .collection("quoteprices")
      .get()
      .then(res => {
        res.forEach(element => {
          element.ref.delete();
        });
      })
      .then(res => {
        return data.forEach(row => {
          firestore.collection("quoteprices").add({
            ...row
          });
        });
      })
      .then(() => {
        dispatch({
          type: "SAVE_PRICING"
        });
      })
      .catch(err => {
        dispatch({ type: "SAVE_PRICING_ERROR", err });
      });
  };
};

export const updatePricingRow = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    let dataToUpdate = { ...data };
    delete dataToUpdate.id;

    firestore
      .collection("quoteprices")
      .doc(data.id)
      .update({
        ...dataToUpdate
      })
      .then(() => {
        dispatch({
          type: "UPDATE_PRICING"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PRICING_ERROR", err });
      });
  };
};

export const addPricingRow = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    firestore
      .collection("quoteprices")
      .add({
        ...data
      })
      .then(() => {
        dispatch({
          type: "UPDATE_PRICING"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PRICING_ERROR", err });
      });
  };
};
