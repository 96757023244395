import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { sendFile } from "../../store/actions/documentActions";

// MUI Stuff
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class SendDialog extends Component {
  state = {
    emails: "",
    emailError: "",
    sending: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.document.documentSent !== prevProps.document.documentSent) {
      this.setState({
        sending: false,
        emails: ""
      });
      this.props.handleClose();
    }
    if (this.props.document.pricingError !== prevProps.document.pricingError) {
      this.setState({
        sending: false,
        emailError: this.props.document.documentError
      });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSend = docData => {
    if (this.state.emails === "") {
      this.setState({ emailError: "Please enter an email address" });
      return;
    }

    this.setState({
      sending: true
    });

    // From name
    let userName = this.props.profile.name;

    // TODO: Sanitize emails?
    let emails = this.state.emails;
    let emailsArr = emails.split(",");

    let data = { ...docData };
    data.emails = emailsArr;
    data.userName = userName;
    this.props.sendFile(data);
  };

  render() {
    const { docData, open, handleClose } = this.props;

    return (
      <Dialog open={open} onClose={handleClose} className="dialog--send">
        <DialogTitle id="alert-dialog-title">Email this file</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter an email address for the recipient. Separate multiple email
            addresses with a comma:
          </DialogContentText>
          <TextField
            id="emails"
            label="Email Address(es)"
            type="text"
            variant="filled"
            value={this.state.emails}
            required
            onChange={this.handleChange}
          />
          {this.state.emailError !== "" && (
            <p className="error">{this.state.emailError}</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => this.handleSend(docData)}
            color="primary"
            disabled={this.state.sending}
            autoFocus
          >
            {this.state.sending ? "Sending..." : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    document: state.document
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendFile: data => dispatch(sendFile(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendDialog);
