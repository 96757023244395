const formatDateToSave = rawDate => {
  let date = new Date(rawDate);
  let dateYear = date.getFullYear();
  let dateMonth = date.getMonth() + 1;
  let dateDay = date.getDate();
  let dateFormatted = dateYear + "-" + dateMonth + "-" + dateDay;
  return dateFormatted;
};

const formatDateToDisplay = rawDate => {
  let date = new Date(rawDate);
  let dateYear = date.getFullYear();
  let dateMonth = date.getMonth() + 1;
  let dateDay = date.getDate();
  let dateFormatted = dateMonth + "/" + dateDay + "/" + dateYear;
  return dateFormatted;
};

const getYearsDiff = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let diff = date2.getTime() - date1.getTime();
  let daysDiff = diff / (1000 * 60 * 60 * 24) - 1;
  let yearPct = daysDiff / 365;
  return (Math.round(yearPct * 4) / 4).toFixed(2);
};

const calcAnnualCosts = (equipment, multiplier) => {
  let cleanEquipment = [];
  const coverages = ["labor", "partsAndLabor", "full"];

  const frequencyMultiplier = {
    BiMonthly: 6,
    Quarterly: 4,
    SemiAnnual: 2,
    Annual: 1
  };

  equipment.forEach(e => {
    let row = { ...e };

    coverages.forEach(level => {
      const combinedKey = level + row.frequency;

      if (row.hasOwnProperty(combinedKey)) {
        // Calc unit cost with multiplier
        const multipliedCost = Number(row[combinedKey] * multiplier).toFixed(2);
        row[combinedKey + "Multiplied"] = multipliedCost;

        // Add annual key for total
        row["annual_" + combinedKey] = Number(
          multipliedCost * frequencyMultiplier[row.frequency]
        ).toFixed(2);
      }
    });

    cleanEquipment.push(row);
  });

  return cleanEquipment;
};

const calcTotalCosts = data => {
  let cleanData = { ...data };

  let coveragesUsed = [];
  const coverages = ["labor", "partsAndLabor", "full"];
  coverages.forEach(level => {
    if (data[level]) {
      coveragesUsed.push(level);
    }
  });

  let totals = {};
  coveragesUsed.forEach(level => {
    totals["total_" + level] = 0;
  });

  data.equipment.forEach(row => {
    coveragesUsed.forEach(level => {
      const combinedKey = "annual_" + level + row.frequency;
      if (row.hasOwnProperty(combinedKey)) {
        let value = Number(row[combinedKey]) * Number(data.serviceTerm);
        totals["total_" + level] += value;
      }
    });
  });

  if (data.discount > 0) {
    const multiplier = 1 - data.discount / 100;

    Object.keys(totals).forEach(key => {
      let value = Number(totals[key]);
      let discounted = value * multiplier;
      totals[key] = discounted;
    });
  }

  Object.keys(totals).forEach(key => {
    cleanData[key] = totals[key];
  });

  return cleanData;
};

export const addQuote = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Format dates
    let closeDate = formatDateToDisplay(data.closeDateRaw);
    let serviceStartDate = formatDateToSave(data.serviceTermStartDateRaw);
    let serviceEndDate = formatDateToSave(data.serviceTermEndDateRaw);
    let currentContractExpiration = formatDateToSave(
      data.currentContractExpirationRaw
    );
    let signatureDate = formatDateToSave(data.signatureDateRaw);

    let serviceTerm = getYearsDiff(
      data.serviceTermStartDateRaw,
      data.serviceTermEndDateRaw
    );
    data.serviceTerm = serviceTerm;

    let equipmentWithAnnuals = calcAnnualCosts(data.equipment, data.multiplier);
    data.equipment = equipmentWithAnnuals;

    let dataWithTotals = calcTotalCosts(data);

    // Create new Firestore doc
    firestore
      .collection("quotes")
      .add({
        ...dataWithTotals,
        closeDate: closeDate,
        serviceTermStartDate: serviceStartDate,
        serviceTermEndDate: serviceEndDate,
        currentContractExpiration: currentContractExpiration,
        signatureDate: signatureDate,
        user: userId,
        userName: profile.name,
        userRegion: profile.region,
        userAddress: profile.address,
        userCity: profile.city,
        userState: profile.state,
        userZip: profile.zip,
        userEmail: profile.email,
        status: "Open",
        date: new Date()
      })
      .then(() => {
        // Increment overall quote count
        return firestore
          .collection("quotecount")
          .doc("count")
          .update({
            number: data.quoteNumber
          });
      })
      .then(() => {
        dispatch({
          type: "CREATE_QUOTE"
        });
      })
      .catch(err => {
        dispatch({ type: "CREATE_QUOTE_ERROR", err });
      });
  };
};

export const updateQuote = data => {
  return (dispatch, getState, getFirebase) => {
    const firestore = getFirebase().firestore();

    // Format dates in case they have changed
    data.closeDate = formatDateToDisplay(data.closeDateRaw);
    data.serviceTermStartDate = formatDateToSave(data.serviceTermStartDateRaw);
    data.serviceTermEndDate = formatDateToSave(data.serviceTermEndDateRaw);
    data.currentContractExpiration = formatDateToSave(
      data.currentContractExpirationRaw
    );
    data.signatureDate = formatDateToSave(data.signatureDateRaw);

    let serviceTerm = getYearsDiff(
      data.serviceTermStartDateRaw,
      data.serviceTermEndDateRaw
    );
    data.serviceTerm = serviceTerm;

    let equipmentWithAnnuals = calcAnnualCosts(data.equipment, data.multiplier);
    data.equipment = equipmentWithAnnuals;

    let dataWithTotals = calcTotalCosts(data);

    // Update Firestore doc
    firestore
      .collection("quotes")
      .doc(dataWithTotals.id)
      .update({
        ...dataWithTotals
      })
      .then(() => {
        dispatch({
          type: "UPDATE_QUOTE"
        });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_QUOTE_ERROR", err });
      });
  };
};

export const quoteReport = data => {
  return (dispatch, getState, getFirebase) => {
    console.log("quoteReport Called", data);
  };
};
