import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "@firebase/app";
import "@firebase/storage";
import FileUploader from "react-firebase-file-uploader";

// Actions
import { createDocument } from "../../store/actions/documentActions";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Components
import DocumentTabs from "./components/DocumentTabs";

class documentsAdd extends Component {
  state = {
    name: "",
    fileURL: "",
    isUploading: false,
    progress: 0
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createDocument(this.state);
    this.props.history.push("/documents/manage");
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("documents")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ fileURL: url }));
  };

  render() {
    const { auth } = this.props;

    // Redirect if logged out
    if (!auth.uid) return <Redirect to="/login" />;

    // Progress bar style
    let progressBarStyle = {
      width: this.state.progress + "%"
    };

    return (
      <>
        <DocumentTabs />
        <h1>Add Document</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="field half">
            <TextField
              id="name"
              label="Document Name"
              type="text"
              variant="filled"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="field half">
            <div className="upload">
              <label className="file-upload">
                Choose File
                <FileUploader
                  name="documents"
                  randomizeFilename
                  storageRef={firebase.storage().ref("documents")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                  required
                />
              </label>
              {this.state.isUploading && (
                <div className="upload-progress">
                  <h5>Uploading</h5>
                  <div className="upload-progress--background">
                    <div
                      className="upload-progress--bar"
                      style={progressBarStyle}
                    ></div>
                  </div>
                </div>
              )}
              {!this.state.isUploading && this.state.fileURL && (
                <div className="upload-preview">
                  <p>
                    File Uploaded:&nbsp;
                    <a
                      href={this.state.fileURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click to Preview
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="field">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.state.isUploading}
            >
              Save Document
            </Button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createDocument: data => dispatch(createDocument(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(documentsAdd);
