import "date-fns";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// Actions
import { addQuote } from "../../store/actions/quoteActions";

// Components
import QuoteTabs from "./components/QuoteTabs";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

// Images
import AddIcon from "../../img/icon-circle-plus.svg";
import RemoveIcon from "../../img/icon-circle-x.svg";
import CopyIcon from "../../img/icon-circle-copy.svg";

class quoteRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceFacilityName: "",
      serviceFacilityAddress: "",
      serviceFacilityAddressCity: "",
      serviceFacilityAddressState: "",
      serviceFacilityAddressZip: "",
      billingSame: true,
      billFacilityName: "",
      billFacilityAddress: "",
      billFacilityAddressCity: "",
      billFacilityAddressState: "",
      billFacilityAddressZip: "",
      facilityContactName: "",
      facilityContactPhone: "",
      facilityContactEmail: "",
      facilityContactTitle: "",
      closeDateRaw: new Date(),
      customerType: "",
      customerStatus: "",
      serviceTermStartDateRaw: new Date(),
      serviceTermEndDateRaw: this.addOneYear(new Date()),
      managedBy: "",
      contractType: "",
      currentContract: "",
      currentContractExpirationRaw: new Date(),
      status: "",
      notes: "",
      purchaseOrder: "",
      discount: 0,
      multiplier: 1,
      labor: false,
      partsAndLabor: false,
      full: false,
      includeSignature: false,
      signatureDateRaw: new Date(),
      equipment: [
        {
          equipmentType: "",
          manufacturer: "",
          model: "",
          serial: "",
          location: "",
          customName: "",
          customCost: "",
          frequency: ""
        }
      ],
      formError: ""
    };
  }

  // Comment/uncomment this for easier testing
  // componentDidMount() {
  //   this.setState({
  //     serviceFacilityName: "Test Hospital",
  //     serviceFacilityAddress: "1234 Address St.",
  //     serviceFacilityAddressCity: "Denver",
  //     serviceFacilityAddressState: "CO",
  //     serviceFacilityAddressZip: "81234",
  //     billingSame: true,
  //     billFacilityName: "",
  //     billFacilityAddress: "",
  //     billFacilityAddressCity: "",
  //     billFacilityAddressState: "",
  //     billFacilityAddressZip: "",
  //     facilityContactName: "First Lastname",
  //     facilityContactPhone: "303-123-4567",
  //     facilityContactEmail: "test@testhospital.com",
  //     facilityContactTitle: "Manager",
  //     closeDateRaw: "2023-03-02T15:37:40.610Z",
  //     customerType: "Hospital",
  //     customerStatus: "New",
  //     serviceTermStartDateRaw: new Date(),
  //     serviceTermEndDateRaw: this.addOneYear(new Date()),
  //     managedBy: "New - Rep",
  //     contractType: "New",
  //     currentContract: "No",
  //     currentContractExpirationRaw: "2023-03-02T15:37:40.610Z",
  //     status: "",
  //     notes: "Test Notes",
  //     purchaseOrder: "123456789",
  //     discount: 0,
  //     multiplier: 1,
  //     labor: true,
  //     partsAndLabor: true,
  //     full: false,
  //     includeSignature: false,
  //     signatureDateRaw: new Date(),
  //     equipment: [
  //       {
  //         equipmentType: "Sterilizer",
  //         manufacturer: "Belimed",
  //         model: "MST 6-6-VS1",
  //         serial: "",
  //         location: "",
  //         customName: "",
  //         customCost: "",
  //         frequency: "Annual",
  //         docId: "2B2PCISsnkTA36w4YrKD"
  //       },
  //       {
  //         equipmentType: "Steam Generator",
  //         manufacturer: "Electrosteam",
  //         model: "LB 150 ",
  //         serial: "",
  //         location: "",
  //         customName: "",
  //         customCost: "",
  //         frequency: "Annual",
  //         docId: "IWFhdOrDlOoTOmrno4ez"
  //       }
  //     ],
  //     formError: ""
  //   });
  // }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSelectChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  };

  handleDateChange = (date, key) => {
    this.setState({
      [key]: date
    });
  };

  handleEquipmentTypeChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return { ...row, equipmentType: e.target.value };
    });
    this.setState({ equipment: newEquipment });
  };

  handleEquipmentManufacturerChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return { ...row, manufacturer: e.target.value };
    });
    this.setState({ equipment: newEquipment });
  };

  handleEquipmentModelChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      const { id } = e.currentTarget.dataset;
      return {
        ...row,
        model: e.target.value,
        docId: id
      };
    });
    this.setState({ equipment: newEquipment });
  };

  handleEquipmentTextChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return { ...row, [e.target.id]: e.target.value };
    });

    this.setState({
      equipment: newEquipment
    });
  };

  handleEquipmentFrequencyChange = i => e => {
    const newEquipment = this.state.equipment.map((row, index) => {
      if (i !== index) return row;
      return {
        ...row,
        frequency: e.target.value
      };
    });
    this.setState({ equipment: newEquipment });
  };

  removeEquipmentRow = i => () => {
    let newEquipment = this.state.equipment;

    if (i > -1) {
      newEquipment.splice(i, 1);
    }

    this.setState({
      equipment: newEquipment
    });
  };

  addEquipmentRow = i => () => {
    this.setState({
      equipment: this.state.equipment.concat([
        {
          equipmentType: "",
          manufacturer: "",
          model: "",
          serial: "",
          location: "",
          customName: "",
          customCost: ""
        }
      ])
    });
  };

  duplicateEquipmentRow = i => () => {
    let newEquipment = this.state.equipment;
    let rowToDup = newEquipment[i];

    newEquipment.splice(i + 1, 0, rowToDup);

    this.setState({
      equipment: newEquipment
    });
  };

  addOneYear = date => {
    date.setFullYear(date.getFullYear() + 1);
    return date;
  };

  getQuoteValues = equipment => {};

  handleSubmit = e => {
    e.preventDefault();

    if (
      this.state.labor === false &&
      this.state.partsAndLabor === false &&
      this.state.full === false
    ) {
      this.setState({
        formError: "You must select at least one type of coverage to quote."
      });
      return;
    }

    this.setState({
      formError: ""
    });

    // Vars/maps used for keying
    const coverages = ["labor", "partsAndLabor", "full"];

    const frequencies = ["BiMonthly", "Quarterly", "SemiAnnual", "Annual"];

    const coveragesStringMap = {
      labor: "Labor Only",
      partsAndLabor: "Parts & Labor",
      full: "Full Service"
    };

    const frequenciesStringMap = {
      BiMonthly: "bi-monthly",
      Quarterly: "quarterly",
      SemiAnnual: "semi-annual",
      Annual: "annual"
    };

    const frequencyMultiplier = {
      BiMonthly: 6,
      Quarterly: 4,
      SemiAnnual: 2,
      Annual: 1
    };

    let dataToSave = { ...this.state };
    let equipment = dataToSave.equipment;
    let prices = this.props.quoteprices;

    let selectedCoverages = [];
    coverages.forEach(level => {
      if (dataToSave[level]) {
        selectedCoverages.push(level);
      }
    });

    // Calculate pricing
    let totals = {};
    let priceError = false;

    // Loop through equipment selected
    equipment.forEach(row => {
      // Bail is an error has already been thrown
      if (priceError) {
        return;
      }

      // Set default cost, replace with custom if specified
      let equipmentCost = 0;
      if (row.customCost !== "") {
        equipmentCost = row.customCost;
      }

      // Find pricing for matching equipment
      let match = null;
      let matches = prices.filter(m => {
        return (
          m.equipmentType === row.equipmentType &&
          m.manufacturer === row.manufacturer &&
          m.model === row.model
        );
      });
      if (matches.length > 0) {
        match = matches[0];
      }

      // Bail if all pricing is missing and no custom cost is available
      if (equipmentCost === 0 && !match) {
        priceError = true;
        this.setState({
          formError: row.manufacturer + " " + row.model + " is missing pricing."
        });
      }

      // Get pricing for each coverage level selected
      selectedCoverages.forEach(level => {
        const combinedKey = level + row.frequency;

        // Get the per-service cost for the unit
        let cost;
        if (equipmentCost !== 0) {
          cost = equipmentCost;
        } else {
          cost = match[combinedKey];
        }

        // Bail if a cost isn't available
        if (cost === 0) {
          priceError = true;
          this.setState({
            formError:
              row.manufacturer +
              " " +
              row.model +
              " is missing pricing for " +
              frequenciesStringMap[row.frequency] +
              " " +
              coveragesStringMap[level] +
              "."
          });
        }

        row[combinedKey] = Number(cost);
      });
    });

    // Bail from the submit process if a price error was detected
    if (priceError) {
      return;
    }

    dataToSave.equipment = equipment;

    // Increment quote number
    let quoteNumbers = this.props.quotecount;
    quoteNumbers.filter(row => {
      return row.id === "count";
    });
    dataToSave.quoteNumber = quoteNumbers[0].number + 1;

    this.props.addQuote(dataToSave);
    this.props.history.push("/quote/open");
  };

  render() {
    const { auth, quoteprices } = this.props;

    // Equipment type dropdown menu
    const renderTypeList = () => {
      const typeList = [...new Set(quoteprices.map(row => row.equipmentType))];

      typeList.push("Custom");

      return typeList.map((val, i) => {
        return (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        );
      });
    };

    // Manufacturers dropdown menu
    const renderManufacturerList = equipmentType => {
      const manufacturerList = quoteprices.filter(
        row => row.equipmentType === equipmentType
      );
      const manufacturerListSorted = manufacturerList.sort((a, b) =>
        a.manufacturer > b.manufacturer ? 1 : -1
      );
      const manufacturerNames = [
        ...new Set(manufacturerListSorted.map(row => row.manufacturer))
      ];

      return manufacturerNames.map((val, i) => {
        return (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        );
      });
    };

    // Models dropdown menu
    const renderModelList = (equipmentType, manufacturer) => {
      const modelList = quoteprices.filter(
        row =>
          row.equipmentType === equipmentType &&
          row.manufacturer === manufacturer
      );
      const modelListSorted = modelList.sort((a, b) =>
        a.model > b.model ? 1 : -1
      );

      return modelListSorted.map((model, i) => {
        return (
          <MenuItem key={i} value={model.model} data-id={model.id}>
            {model.model}
          </MenuItem>
        );
      });
    };

    // Redirect if logged out
    if (!auth.uid) return <Redirect to="/login" />;

    return (
      <>
        <QuoteTabs />
        <h1>Build Contract Quote</h1>

        <form onSubmit={this.handleSubmit}>
          <div className="field">
            <h2>Facility Information:</h2>
          </div>

          <div className="field">
            <TextField
              id="serviceFacilityName"
              label="Service Facility Name"
              type="text"
              variant="filled"
              value={this.state.serviceFacilityName}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field">
            <TextField
              id="serviceFacilityAddress"
              label="Service Address"
              type="text"
              variant="filled"
              value={this.state.serviceFacilityAddress}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field half">
            <TextField
              id="serviceFacilityAddressCity"
              label="City"
              type="text"
              variant="filled"
              value={this.state.serviceFacilityAddressCity}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field quarter">
            <FormControl variant="filled">
              <InputLabel id="serviceFacilityAddressState-label">
                State *
              </InputLabel>
              <Select
                id="serviceFacilityAddressState"
                labelId="serviceFacilityAddressState-label"
                variant="filled"
                label="State"
                required
                value={this.state.serviceFacilityAddressState}
                onChange={this.handleSelectChange(
                  "serviceFacilityAddressState"
                )}
              >
                <MenuItem value="AL">Alabama</MenuItem>
                <MenuItem value="AK">Alaska</MenuItem>
                <MenuItem value="AZ">Arizona</MenuItem>
                <MenuItem value="AR">Arkansas</MenuItem>
                <MenuItem value="CA">California</MenuItem>
                <MenuItem value="CO">Colorado</MenuItem>
                <MenuItem value="CT">Connecticut</MenuItem>
                <MenuItem value="DE">Delaware</MenuItem>
                <MenuItem value="DC">District Of Columbia</MenuItem>
                <MenuItem value="FL">Florida</MenuItem>
                <MenuItem value="GA">Georgia</MenuItem>
                <MenuItem value="HI">Hawaii</MenuItem>
                <MenuItem value="ID">Idaho</MenuItem>
                <MenuItem value="IL">Illinois</MenuItem>
                <MenuItem value="IN">Indiana</MenuItem>
                <MenuItem value="IA">Iowa</MenuItem>
                <MenuItem value="KS">Kansas</MenuItem>
                <MenuItem value="KY">Kentucky</MenuItem>
                <MenuItem value="LA">Louisiana</MenuItem>
                <MenuItem value="ME">Maine</MenuItem>
                <MenuItem value="MD">Maryland</MenuItem>
                <MenuItem value="MA">Massachusetts</MenuItem>
                <MenuItem value="MI">Michigan</MenuItem>
                <MenuItem value="MN">Minnesota</MenuItem>
                <MenuItem value="MS">Mississippi</MenuItem>
                <MenuItem value="MO">Missouri</MenuItem>
                <MenuItem value="MT">Montana</MenuItem>
                <MenuItem value="NE">Nebraska</MenuItem>
                <MenuItem value="NV">Nevada</MenuItem>
                <MenuItem value="NH">New Hampshire</MenuItem>
                <MenuItem value="NJ">New Jersey</MenuItem>
                <MenuItem value="NM">New Mexico</MenuItem>
                <MenuItem value="NY">New York</MenuItem>
                <MenuItem value="NC">North Carolina</MenuItem>
                <MenuItem value="ND">North Dakota</MenuItem>
                <MenuItem value="OH">Ohio</MenuItem>
                <MenuItem value="OK">Oklahoma</MenuItem>
                <MenuItem value="OR">Oregon</MenuItem>
                <MenuItem value="PA">Pennsylvania</MenuItem>
                <MenuItem value="RI">Rhode Island</MenuItem>
                <MenuItem value="SC">South Carolina</MenuItem>
                <MenuItem value="SD">South Dakota</MenuItem>
                <MenuItem value="TN">Tennessee</MenuItem>
                <MenuItem value="TX">Texas</MenuItem>
                <MenuItem value="UT">Utah</MenuItem>
                <MenuItem value="VT">Vermont</MenuItem>
                <MenuItem value="VA">Virginia</MenuItem>
                <MenuItem value="WA">Washington</MenuItem>
                <MenuItem value="WV">West Virginia</MenuItem>
                <MenuItem value="WI">Wisconsin</MenuItem>
                <MenuItem value="WY">Wyoming</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="field quarter">
            <TextField
              id="serviceFacilityAddressZip"
              label="Zip"
              type="text"
              variant="filled"
              value={this.state.serviceFacilityAddressZip}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field">
            <h2>Billing Information:</h2>
            <label>
              <Checkbox
                id="billingSame"
                label="Same as Facility Info"
                checked={this.state.billingSame}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">Same as Facility Info</span>
            </label>

            {this.state.billingSame === false && (
              <div className="billing-toggle">
                <div className="field">
                  <TextField
                    id="billFacilityName"
                    label="Billing Facility Name"
                    type="text"
                    variant="filled"
                    value={this.state.billFacilityName}
                    required
                    onChange={this.handleChange}
                  />
                </div>

                <div className="field">
                  <TextField
                    id="billFacilityAddress"
                    label="Billing Address"
                    type="text"
                    variant="filled"
                    value={this.state.billFacilityAddress}
                    required
                    onChange={this.handleChange}
                  />
                </div>

                <div className="field half">
                  <TextField
                    id="billFacilityAddressCity"
                    label="City"
                    type="text"
                    variant="filled"
                    value={this.state.billFacilityAddressCity}
                    required
                    onChange={this.handleChange}
                  />
                </div>

                <div className="field quarter">
                  <FormControl variant="filled">
                    <InputLabel id="billFacilityAddressState-label">
                      State *
                    </InputLabel>
                    <Select
                      id="billFacilityAddressState"
                      labelId="billFacilityAddressState-label"
                      variant="filled"
                      label="State"
                      required
                      value={this.state.billFacilityAddressState}
                      onChange={this.handleSelectChange(
                        "billFacilityAddressState"
                      )}
                    >
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="field quarter">
                  <TextField
                    id="billFacilityAddressZip"
                    label="Zip"
                    type="text"
                    variant="filled"
                    value={this.state.billFacilityAddressZip}
                    required
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="field">
            <h2>Facility Contact:</h2>
          </div>
          <div className="field half">
            <TextField
              id="facilityContactName"
              label="Facility Contact Name"
              type="text"
              variant="filled"
              value={this.state.facilityContactName}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field half">
            <TextField
              id="facilityContactPhone"
              label="Facility Contact Phone"
              type="phone"
              variant="filled"
              value={this.state.facilityContactPhone}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field half">
            <TextField
              id="facilityContactEmail"
              label="Facility Contact Email"
              type="email"
              variant="filled"
              value={this.state.facilityContactEmail}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field half">
            <TextField
              id="facilityContactTitle"
              label="Facility Contact Title"
              type="text"
              variant="filled"
              value={this.state.facilityContactTitle}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="field">
            <h2>Customer Information:</h2>
          </div>

          <div className="field quarter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="closeDate"
                label="Expected Close Date *"
                value={this.state.closeDateRaw}
                onChange={e => this.handleDateChange(e, "closeDateRaw")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="field quarter">
            <FormControl variant="filled">
              <InputLabel id="customerType-label">Customer Type *</InputLabel>
              <Select
                id="customerType"
                labelId="customerType-label"
                variant="filled"
                label="Customer Type"
                required
                value={this.state.customerType}
                onChange={this.handleSelectChange("customerType")}
              >
                <MenuItem value="Hospital">Hospital</MenuItem>
                <MenuItem value="Surgery Center">Surgery Center</MenuItem>
                <MenuItem value="Clinic">Clinic</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="field quarter">
            <FormControl variant="filled">
              <InputLabel id="customerStatus-label">
                Customer Status *
              </InputLabel>
              <Select
                id="customerStatus"
                labelId="customerStatus-label"
                variant="filled"
                label="Customer Status"
                required
                value={this.state.customerStatus}
                onChange={this.handleSelectChange("customerStatus")}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Existing">Existing</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="field quarter">
            <TextField
              id="purchaseOrder"
              label="Purchase Order #"
              type="text"
              variant="filled"
              value={this.state.purchaseOrder}
              onChange={this.handleChange}
            />
          </div>

          <div className="field">
            <h2>Contract Terms:</h2>
          </div>

          <div className="field quarter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="serviceTermStartDateRaw"
                label="Term Start Date *"
                value={this.state.serviceTermStartDateRaw}
                onChange={e =>
                  this.handleDateChange(e, "serviceTermStartDateRaw")
                }
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="field quarter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="serviceTermEndDateRaw"
                label="Term End Date *"
                value={this.state.serviceTermEndDateRaw}
                onChange={e =>
                  this.handleDateChange(e, "serviceTermEndDateRaw")
                }
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="field quarter">
            <FormControl variant="filled">
              <InputLabel id="managedBy-label">Managed By *</InputLabel>
              <Select
                id="managedBy"
                labelId="managedBy-label"
                variant="filled"
                label="Managed By"
                required
                value={this.state.managedBy}
                onChange={this.handleSelectChange("managedBy")}
              >
                <MenuItem value="New - Rep">New - Rep</MenuItem>
                <MenuItem value="New - Dept">New - Dept</MenuItem>
                <MenuItem value="Renewal - Rep">Renewal - Rep</MenuItem>
                <MenuItem value="Renewal - Dept">Renewal - Dept</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="field quarter">
            <FormControl variant="filled">
              <InputLabel id="managedBy-label">Contract Type *</InputLabel>
              <Select
                id="contractType"
                labelId="contractType-label"
                variant="filled"
                label="Contract Type"
                required
                value={this.state.contractType}
                onChange={this.handleSelectChange("contractType")}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Renewal">Renewal</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="field quarter">
            <FormControl variant="filled">
              <InputLabel id="currentContract-label">
                Is this customer currently under contract? *
              </InputLabel>
              <Select
                id="currentContract"
                labelId="currentContract-label"
                variant="filled"
                label="Current Contract"
                required
                value={this.state.currentContract}
                onChange={this.handleSelectChange("currentContract")}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </FormControl>
          </div>

          {this.state.currentContract === "Yes" && (
            <div className="field quarter">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="currentContractExpirationRaw"
                  label="Current Contract Expiration *"
                  value={this.state.currentContractExpirationRaw}
                  onChange={e =>
                    this.handleDateChange(e, "serviceTermEndDateRaw")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}

          <div className="field">
            <h2>Quotes to Build:</h2>
          </div>

          <div className="field half coverage-levels">
            <h6>Types of Coverage to Quote:</h6>
            <label>
              <Checkbox
                id="labor"
                label="Labor Only"
                checked={this.state.labor}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">Labor Only</span>
            </label>
            <label>
              <Checkbox
                id="partsAndLabor"
                label="Parts & Labor"
                checked={this.state.partsAndLabor}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">Parts & Labor</span>
            </label>
            <label>
              <Checkbox
                id="full"
                label="Full Service"
                checked={this.state.full}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">Full Service</span>
            </label>
          </div>

          <div className="field half coverage-levels">
            <h6>Quote Options:</h6>
            <label>
              <Checkbox
                id="includeSignature"
                label="Include signature on quotes?"
                checked={this.state.includeSignature}
                color="primary"
                onChange={this.handleCheckboxChange}
              />
              <span className="checkbox-label">
                Include signature on quotes?
              </span>
            </label>
            {this.state.includeSignature && (
              <div className="field--internal half">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="signatureDateRaw"
                    label="Signature Date"
                    value={this.state.signatureDateRaw}
                    onChange={e => this.handleDateChange(e, "signatureDateRaw")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            )}
          </div>

          <div className="field">
            <h2>Equipment:</h2>
          </div>

          {quoteprices && (
            <div className="equipment">
              {this.state.equipment.map((row, i) => (
                <div className="equipment--selects" key={i}>
                  <div className="field">
                    <FormControl variant="filled">
                      <InputLabel id="make-label">Equipment Type *</InputLabel>
                      <Select
                        id="equipmentType"
                        labelId="make-label"
                        variant="filled"
                        label="equipmentType"
                        required
                        value={row.equipmentType}
                        onChange={this.handleEquipmentTypeChange(i)}
                      >
                        {renderTypeList()}
                      </Select>
                    </FormControl>
                  </div>

                  {row.equipmentType === "Custom" ? (
                    <>
                      <div className="field">
                        <TextField
                          id="customName"
                          label="Custom Equipment Name"
                          type="text"
                          variant="filled"
                          required
                          value={row.customName}
                          onChange={this.handleEquipmentTextChange(i)}
                        />
                      </div>
                      <div className="field">
                        <TextField
                          id="customCost"
                          label="Cost Per Service"
                          type="number"
                          variant="filled"
                          value={row.customCost}
                          min="1"
                          required
                          onChange={this.handleEquipmentTextChange(i)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="field">
                        <FormControl variant="filled">
                          <InputLabel id="make-label">
                            Manufacturer *
                          </InputLabel>
                          <Select
                            id="make"
                            labelId="make-label"
                            variant="filled"
                            label="make"
                            required
                            value={row.make}
                            onChange={this.handleEquipmentManufacturerChange(i)}
                          >
                            {renderManufacturerList(row.equipmentType)}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="field">
                        <FormControl variant="filled">
                          <InputLabel id="model-label">Model *</InputLabel>
                          <Select
                            id="model"
                            labelId="model-label"
                            variant="filled"
                            label="model"
                            required
                            value={row.model}
                            onChange={this.handleEquipmentModelChange(i)}
                          >
                            {renderModelList(
                              row.equipmentType,
                              row.manufacturer
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </>
                  )}

                  <div className="field">
                    <FormControl variant="filled">
                      <InputLabel id="frequency-label">
                        Service Frequency *
                      </InputLabel>
                      <Select
                        id="equipmentFrequency"
                        labelId="frequency-label"
                        variant="filled"
                        label="equipmentFrequency"
                        required
                        value={row.frequency}
                        onChange={this.handleEquipmentFrequencyChange(i)}
                      >
                        <MenuItem value="BiMonthly">
                          Bi-monthly (6 times per year)
                        </MenuItem>
                        <MenuItem value="Quarterly">
                          Quarterly (4 times per year)
                        </MenuItem>
                        <MenuItem value="SemiAnnual">
                          Semi-annually (2 times per year)
                        </MenuItem>
                        <MenuItem value="Annual">
                          Annually (1 time per year)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="field">
                    <TextField
                      id="serial"
                      label="Serial #"
                      type="text"
                      variant="filled"
                      value={row.serial}
                      min="1"
                      onChange={this.handleEquipmentTextChange(i)}
                    />
                  </div>
                  <div className="field">
                    <TextField
                      id="location"
                      label="Location (Room)"
                      type="text"
                      variant="filled"
                      value={row.location}
                      onChange={this.handleEquipmentTextChange(i)}
                    />
                  </div>
                  <div className="field actions">
                    <Button
                      className="equipment--add"
                      variant="contained"
                      color="primary"
                      onClick={this.addEquipmentRow(i)}
                    >
                      <img src={AddIcon} alt="icon" />
                    </Button>
                    <Button
                      className="equipment--duplicate"
                      variant="contained"
                      color="primary"
                      onClick={this.duplicateEquipmentRow(i)}
                    >
                      <img src={CopyIcon} alt="icon" />
                    </Button>
                    {i > 0 && (
                      <Button
                        className="equipment--remove"
                        variant="contained"
                        color="primary"
                        onClick={this.removeEquipmentRow(i)}
                      >
                        <img src={RemoveIcon} alt="icon" />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="field">
            <h2>Optional Discounts & Multipliers:</h2>
          </div>

          <div className="field quarter">
            <TextField
              id="discount"
              label="Discount %"
              type="number"
              variant="filled"
              value={this.state.discount}
              min="0"
              onChange={this.handleChange}
            />
          </div>

          <div className="field quarter">
            <TextField
              id="multiplier"
              label="Multiplier"
              type="number"
              variant="filled"
              value={this.state.multiplier}
              min="0"
              onChange={this.handleChange}
            />
          </div>

          <div className="field">
            <h2>Additional Information:</h2>
          </div>

          <div className="field">
            <TextField
              id="notes"
              label="Notes"
              variant="filled"
              multiline={true}
              rows={5}
              rowsMax={5}
              value={this.state.notes}
              onChange={this.handleChange}
            ></TextField>
          </div>

          <div className="field">
            {this.state.formError && (
              <p className="red">{this.state.formError}</p>
            )}
            <Button type="submit" variant="contained" color="primary">
              Build Quote
            </Button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    quotecount: state.firestore.ordered.quotecount,
    quoteprices: state.firestore.ordered.quoteprices,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addQuote: data => dispatch(addQuote(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "quoteprices",
      orderBy: ["manufacturer", "asc"]
    },
    {
      collection: "quotecount"
    }
  ])
)(quoteRequest);
